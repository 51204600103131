/**
 * host主播模块接口列表
 */

import base from "./base"; // 导入接口域名列表
import axios from "../http"; // 导入http中创建的axios实例

const reportData = {
  // 上报数据
  report(params) {
    return axios.post(
      `${base.baseUrl}/auth-service/open/reportData/h5`,
      params
    );
  }
};

export default reportData;
