<template>
  <div class="mr16 ml16">
    <BackAndTitle :path="'/'" :title="'Withdrawal account'" />
    <van-form v-if="PaymentChannel" class="mt24" ref="form">
      <!-- 编辑状态 -->
      <template v-if="state == 'edit'">
        <van-field label="Your ID" :value="username" disabled />
      </template>
      <!-- 二次确认和绑定的状态 -->
      <template v-if="state == 'affirm' || state == 'bound'">
        <van-cell title="Your ID" :value="username" />
      </template>
      <!-- 绑定的状态 -->
      <div class="check-hint mt24 mb24" v-if="state == 'affirm'">
        {{$t('Please check carefully to ensure that the information is correct, otherwise the transfer will not be possible.')}}
      </div>
      <!-- ▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼ PayerMax的收款人信息 ▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼ -->
      <template v-if="PaymentChannel == 'PAYERMAX'">
        <!-- 编辑状态 -->
        <template v-if="state == 'edit'">
          <van-field :clearable="true" v-model.trim="PayerMaxPayeeAccountNo" name="pattern"
            :label="$t('Bank Account Number (IBAN)')"
            :placeholder="$t('Please enter bank account number')" :maxlength="26" show-word-limit
            :rules="[
              { required: true, message: $t('Bank account number must be entered') },
              {
                pattern: /^TR[0-9]{24}$/,
                message: $t('The value must start with TR plus 24 digits')
              }
            ]" />
          <van-field :clearable="true" v-model.trim="PayerMaxPayeeAccountName" name="pattern"
            :label="$t('bank account name')" :placeholder="$t('Please enter bank account name')"
            :rules="[
              { required: true, message: $t('Bank account name must be entered') },
              {
                pattern: /^[a-zA-Z0-9-\s\.]{0,100}$/,
                message: $t('Can only contain letters, numbers, spaces, dashes (-), dots (.)')
              }
            ]" />
        </template>
        <!-- 二次确认和绑定的状态 -->
        <template v-if="state == 'affirm' || state == 'bound'">
          <van-cell :title="$t('Bank Account Number (IBAN)')" :value="PayerMaxPayeeAccountNo" />
          <van-cell :title="$t('bank account name')" :value="PayerMaxPayeeAccountName" />
        </template>
      </template>
      <!-- ▲▲▲▲▲▲▲▲▲▲▲▲▲▲▲ PayerMax的收款人信息 ▲▲▲▲▲▲▲▲▲▲▲▲▲▲▲ -->
      <!-- ▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼ Airwallex的收款人信息 ▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼ -->
      <template v-if="PaymentChannel == 'AIRWALLEX'">
        <!-- 编辑状态 -->
        <template v-if="state == 'edit'">
          <!-- <span class="sub-title mt16 mb8">1.{{$t('Personal information')}}</span>
          <van-field :clearable="true" v-model.trim="AirwallexFirstName" name="pattern"
            :label="$t('First Name')" :placeholder="$t('Please enter your first name')"
            :rules="[{ required: true, message: $t('First name is required') }]" />
          <van-field :clearable="true" v-model.trim="AirwallexLastName" name="pattern"
            :label="$t('Last Name')" :placeholder="$t('Please enter your last name')"
            :rules="[{ required: true, message: $t('Last name is required') }]" /> -->
          <span class="sub-title mt16 mb8">{{$t('Bank information')}}</span>
          <van-field :clearable="true" v-model.trim="AirwallexSwiftNo" name="pattern"
            :label="$t('SWIFT number')" :placeholder="$t('Please enter SWIFT number')"
            :rules="[{ required: true, message: $t('SWIFT number must be entered') }]" />
          <van-field :clearable="true" v-model.trim="AirwallexBankName" name="pattern"
            :label="$t('Bank name')" :placeholder="$t('Please enter bank name')"
            :rules="[{ required: true, message: $t('Bank name is required') }]" />
          <van-field :clearable="true" v-model.trim="AirwallexPayeeAccountNo" name="pattern"
            :label="$t('Bank Account Number (IBAN)')"
            :placeholder="$t('Please enter bank account number')" :maxlength="26" show-word-limit
            :rules="[
              { required: true, message: $t('Bank account number must be entered') },
              {
                pattern: /^TR[0-9]{24}$/,
                message: $t('The value must start with TR plus 24 digits')
              }
            ]" />
          <van-field :clearable="true" v-model.trim="AirwallexPayeeAccountName" name="pattern"
            :label="$t('bank account name')" :placeholder="$t('Please enter bank account name')"
            :rules="[
              { required: true, message: $t('Bank account name must be entered') },
              {
                pattern: /^[a-zA-Z0-9-\s\.]{0,100}$/,
                message: '只能包含英文、数字、空格、中划线(-)、点(.)'
              }
            ]" />
          <van-field :clearable="true" v-model.trim="province" name="pattern"
            :label="$t('Province')" :placeholder="$t('Please enter province')"
            :rules="[{ required: true, message: $t('Province must be entered') }]" />
          <van-field :clearable="true" v-model.trim="city" name="pattern" :label="$t('City')"
            :placeholder="$t('Please enter city')"
            :rules="[{ required: true, message: $t('City must be entered') }]" />
          <van-field :clearable="true" v-model.trim="street" name="pattern" :label="$t('Street')"
            :placeholder="$t('Please enter street')"
            :rules="[{ required: true, message: $t('Street must be entered') }]" />
          <van-field :clearable="true" v-model.trim="postalCode" name="pattern"
            :label="$t('Postcode')" :placeholder="$t('Please enter postcode')"
            :rules="[{ required: true, message: $t('Postcode must be entered') }]" />
        </template>
        <!-- 二次确认和绑定的状态 -->
        <template v-if="state == 'affirm' || state == 'bound'">
          <!-- <span class="sub-title mt16 mb8">1.{{$t('Personal information')}}</span>
          <van-cell :title="$t('First Name')" :value="AirwallexFirstName" />
          <van-cell :title="$t('Last Name')" :value="AirwallexLastName" /> -->
          <span class="sub-title mt16 mb8">{{$t('Bank information')}}</span>
          <van-cell :title="$t('SWIFT number')" :value="AirwallexSwiftNo" />
          <van-cell :title="$t('Bank name')" :value="AirwallexBankName" />
          <van-cell :title="$t('Bank Account Number (IBAN)')" :value="AirwallexPayeeAccountNo" />
          <van-cell :title="$t('bank account name')" :value="AirwallexPayeeAccountName" />
          <van-cell :title="$t('Province')" :value="province" />
          <van-cell :title="$t('City')" :value="city" />
          <van-cell :title="$t('Street')" :value="street" />
          <van-cell :title="$t('Postcode')" :value="postalCode" />
        </template>
      </template>
      <!-- ▲▲▲▲▲▲▲▲▲▲▲▲▲▲▲ Airwallex的收款人信息 ▲▲▲▲▲▲▲▲▲▲▲▲▲▲▲ -->
      <!-- ▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼ Papara收款人信息 ▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼ -->
      <template v-if="PaymentChannel == 'PAPARA'">
        <div class="papara-logo">
          <img src="@/assets/papara.png" alt="">
        </div>
        <!-- 编辑状态 -->
        <template v-if="state == 'edit'">
          <van-field name="radio" :label="$t('Account type')" :rules="[
              { required: true, message: $t('Account type must be entered') }
            ]">
            <template #input>
              <van-radio-group v-model="paparaAccountType">
                <van-radio name="ID">{{$t('Account ID')}}</van-radio>
                <van-radio name="PHONE">{{$t('The phone')}}</van-radio>
                <van-radio name="EMAIL">{{$t('email')}}</van-radio>
              </van-radio-group>
            </template>
          </van-field>
          <!-- <van-field :clearable="true" v-model.trim="PaparaName" name="pattern"
            :label="$t('Papara')" :placeholder="$t('Please enter your first name')"
            :rules="[{ required: true, message: $t('Papara number must be entered') }]" /> -->
          <van-field :clearable="true" v-model.trim="PaparaAccountName" name="pattern"
            :label="$t('Papara account name')" :placeholder="$t('Please enter Papara account name')"
            :rules="[
              { required: true, message: $t('Papara account name must be entered') }
            ]" />
          <span
            class="papara-hint">{{$t('*The account name can be the registered Papara mobile number, email or Papara number')}}</span>
        </template>
        <!-- 二次确认和绑定的状态 -->
        <template v-if="state == 'affirm' || state == 'bound'">
          <van-cell :title="$t('Account type')" :value="$t(paparaAccountType)" />
          <!-- <van-cell :title="$t('Papara')" :value="PaparaName" /> -->
          <van-cell :title="$t('Papara account name')" :value="PaparaAccountName" />
        </template>
      </template>
      <!-- ▲▲▲▲▲▲▲▲▲▲▲▲▲▲▲ Papara收款人信息 ▲▲▲▲▲▲▲▲▲▲▲▲▲▲▲ -->

      <!-- ▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼ 操作按钮 ▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼ -->
      <!-- 编辑状态 -->
      <template v-if="state == 'edit'">
        <van-button @click="handleSave" color="#39cc80" block>{{$t('save')}}</van-button>
        <div class="register-box">
          <span>{{$t('No Papara')}}?, <a
              href="https://www.papara.com/personal/auth/register/phone">{{$t('Go to Register')}}></a></span>
        </div>
      </template>
      <!-- 二次确认状态 -->
      <template v-if="state == 'affirm'">
        <van-button @click="handleAffirm" color="#39cc80" block>确认提交</van-button>
        <van-button native-type="button" @click="handleEdit" color="#39cc80" block plain>返回修改
        </van-button>
      </template>
      <!-- 绑定状态 -->
      <template v-if="state == 'bound'">
        <van-button @click="handleEdit" color="#39cc80" block>修改信息</van-button>
      </template>
      <!-- ▲▲▲▲▲▲▲▲▲▲▲▲▲▲▲ 操作按钮 ▲▲▲▲▲▲▲▲▲▲▲▲▲▲▲ -->
    </van-form>
  </div>
</template>

<script>
import BackAndTitle from "@/components/BackAndTitle";
export default {
  name: "WithdrawalAccountInfo",
  components: { BackAndTitle },
  data() {
    return {
      PaymentChannel: "",
      // PaymentChannel的值可选范围:- PAYERMAX - AIRWALLEX - PAPARA -,
      state: "edit",
      // 1、- edit   - 是编辑状态，可修改信息,按钮为保存（验证信息，进入二次确认状态）；
      // 2、- affirm - 是二次确认状态，不可修改信息，按钮为确认提交（提交信息到后台）和返回修改（进入编辑状态）；
      // 3、- bound  - 是已绑定状态，按钮是修改信息（进入编辑状态）；
      // -----------------------------------------------------------
      username: "",
      id: 0,
      // PayerMax的数据
      PayerMaxPayeeAccountNo: "",
      PayerMaxPayeeAccountName: "",
      // Airwallex的数据
      AirwallexFirstName: "",
      AirwallexLastName: "",
      AirwallexSwiftNo: "",
      AirwallexBankName: "",
      AirwallexPayeeAccountNo: "",
      AirwallexPayeeAccountName: "",
      province: "",
      city: "",
      street: "",
      postalCode: "",
      // Papara的数据
      paparaAccountType: "",
      PaparaName: "",
      PaparaAccountName: "",
    };
  },
  methods: {
    handleSave() {
      this.$refs.form.validate().then(() => {
        console.log("保存动作：验证成功，等待二次确认。");
        this.state = "affirm";
      });
    },
    handleEdit() {
      console.log("返回修改");
      this.state = "edit";
    },
    handleAffirm() {
      console.log(
        "确认保存，根据付款渠道发送相应参数进行保存，刷新页面进入bound"
      );
      let params = {};
      // if(this.PaymentChannel=='PAYERMAX'){}else if(){}
      switch (this.PaymentChannel) {
        case "PAYERMAX":
          params = {
            bankAccountName: this.PayerMaxPayeeAccountName,
            bankAccountNumber: this.PayerMaxPayeeAccountNo,
            id: this.id,
          };
          break;
        case "AIRWALLEX":
          params = {
            bankAccountName: this.AirwallexPayeeAccountName,
            bankAccountNumber: this.AirwallexPayeeAccountNo,
            bankName: this.AirwallexBankName,
            swift: this.AirwallexSwiftNo,
            // firstName: this.AirwallexFirstName,
            // lastName: this.AirwallexLastName,
            province: this.province,
            city: this.city,
            street: this.street,
            postalCode: this.postalCode,
            id: this.id,
          };
          break;
        case "PAPARA":
          params = {
            paparaAccountType: this.paparaAccountType,
            firstName: this.PaparaName,
            bankAccountName: this.PaparaAccountName,
            id: this.id,
          };
          break;

        default:
          break;
      }
      this.$api.host.setWithdrawAccount(params).then((res) => {
        console.log("setWithdrawAccount res", res.data);
        if (res.code == 0) {
          this.$toast("保存成功");
          setTimeout(() => {
            this.state = "bound";
          }, 1000);
        }
      });
    },
  },
  created() {
    this.$api.host.getWithdrawAccount().then((res) => {
      if (res.data?.id != 0) {
        // id不等于为绑定状态
        this.state = "bound";
      } else {
        this.state = "edit";
      }
      this.PaymentChannel = res?.data?.payChannel;
      // PaymentChannel的值可选范围:- PAYERMAX - AIRWALLEX - PAPARA -,
      // this.PaymentChannel = "PAPARA";
      // state:- edit - bound - affirm -,
      // this.state = "affirm";
      this.username = res?.data?.username;
      this.id = res?.data?.id;
      this.AirwallexFirstName = res?.data?.firstName;
      this.AirwallexLastName = res?.data?.lastName;
      this.AirwallexSwiftNo = res?.data?.swift;
      this.AirwallexBankName = res?.data?.bankName;
      this.AirwallexPayeeAccountNo = res?.data?.bankAccountNumber;
      this.AirwallexPayeeAccountName = res?.data?.bankAccountName;
      this.PayerMaxPayeeAccountNo = res?.data?.bankAccountNumber;
      this.PayerMaxPayeeAccountName = res?.data?.bankAccountName;
      this.paparaAccountType = res?.data?.paparaAccountType;
      this.PaparaName = res?.data?.firstName;
      this.PaparaAccountName = res?.data?.bankAccountName;
      this.province = res?.data?.province;
      this.city = res?.data?.city;
      this.street = res?.data?.street;
      this.postalCode = res?.data?.postalCode;
    });
  },
};
</script>

<style lang="less" scoped>
.sub-title {
  display: inline-block;
  height: 24px;
  font-size: 16px;
  font-family: Roboto-Bold, Roboto, Noto Sans, SF UI Text-Bold, SF UI Text,
    Helvetica;
  font-weight: bold;
  color: #121212;
  line-height: 24px;
}

.check-hint {
  font-size: 16px;
  font-family: Roboto-Bold, Roboto, Noto Sans, SF UI Text-Bold, SF UI Text,
    Helvetica;
  font-weight: bold;
  color: #121212;
  line-height: 24px;
  text-align: center;
}

.papara-hint {
  color: #c8c9cc;
  font-size: 14px;
  line-height: 24px;
  display: inline-block;
  margin: 8px 16px 0;
}

.van-button--default {
  width: 280px;
  margin: 16px auto;
  border-radius: 8px;
}
.van-button__content {
  color: #121212;
}
.van-radio {
  margin-bottom: 10px;
}
.papara-logo {
  margin: 16px auto;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 137px;
    height: 38px;
  }
}
.register-box {
  margin: 16px auto;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  color: #c8c9cc;
  text-align: center;
  a {
    color: #646566;
  }
}
</style>
