<template>
  <div class="safe-warp">
    <span>{{$t('Need help?')}}</span>
    <span class="help" @click="openAIHelp()">{{$t('Go to help center')}}.</span>
  </div>
</template>

<script>
export default {
  name: "HelpCenter",
};
</script>

<style lang="less" scoped>
.safe-warp {
  margin: 24px 0;
  height: 22px;
  font-size: 14px;
  font-family: Roboto-Bold, Roboto, Noto Sans, SF UI Text-Medium, SF UI Text,
    Helvetica;
  font-weight: 500;
  color: #121212;
  text-align: center;
  line-height: 22px;
  .help {
    color: #2997ff;
  }
}
</style>
