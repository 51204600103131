<template>
  <div class="warp">
    <span>{{$t('Encounter problems?')}}</span>
    <a href="luckluck://function/email">{{$t('Immediate Feedback')}}</a>
  </div>
</template>

<script>
export default {
  name: "QuickFeedback",
  //props接收mailto的参数
  props: ["email"],
};
</script>

<style lang="less" scoped>
.warp {
  margin: 30px 0 24px 0;
  height: 22px;
  font-size: 14px;
  font-family: Roboto-Bold, Roboto, Noto Sans, SF UI Text-Medium, SF UI Text,
    Helvetica;
  font-weight: 500;
  color: #121212;
  text-align: center;
  line-height: 22px;
  a {
    color: #2997ff;
  }
}
</style>
