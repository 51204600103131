//该文件专门用于创建整个应用的路由器
import VueRouter from "vue-router";
// 避免重复点击路由报错 Avoided redundant navigation to current location
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err);
};

//引入组件
import AnchorCenter from "@/pages/AnchorCenter.vue";
import IncomeReport from "@/pages/IncomeReport.vue";
import PhonePrice from "@/pages/PhonePrice.vue";
import IncomeDetails from "@/pages/IncomeDetails.vue";
import WithdrawalAccountInfo from "@/pages/WithdrawalAccountInfo.vue";
import NoAccess from "@/pages/NoAccess.vue";
import DeepLink from "@/pages/DeepLink.vue";
import PayCoin from "@/pages/PayCoin.vue";
import PayStatus from "@/pages/PayStatus.vue";

//创建并暴露一个路由器
export default new VueRouter({
  mode: "history",
  base: "/",
  routes: [
    {
      path: "/",
      redirect: "/AnchorCenter"
    },
    {
      path: "/AnchorCenter",
      name: "AnchorCenter",
      component: AnchorCenter
    },
    {
      path: "/AnchorCenter/IncomeReport",
      name: "AnchorCenterIncomeReport",
      component: IncomeReport
    },
    {
      path: "/AnchorCenter/IncomeReport/IncomeDetails",
      name: "AnchorCenterIncomeReportIncomeDetails",
      component: IncomeDetails
    },
    {
      path: "/AnchorCenter/PhonePrice",
      name: "AnchorCenterPhonePrice",
      component: PhonePrice
    },
    {
      path: "/AnchorCenter/WithdrawalAccountInfo",
      name: "AnchorCenterWithdrawalAccountInfo",
      component: WithdrawalAccountInfo
    },
    {
      path: "/NoAccess",
      name: "NoAccess",
      component: NoAccess
    },
    {
      path: "/DeepLink",
      name: "DeepLink",
      component: DeepLink
    },
    {
      path: "/pay",
      name: "PayCoin",
      component: PayCoin
    },
    {
      path: "/pay/status",
      name: "PayStatus",
      component: PayStatus
    }
  ]
});
