<template>
  <div class="">
    <div class="placehold"></div>
    <div class="title">
      <div @click="loadPage(path)" class="back">
        <img src="@/assets/Icon-20x20-Back@2x.png" alt="" />
      </div>
      <span class="text">{{ $t(title) }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "backAndTitle",
  //props接收mailto的参数
  props: ["title", "path"],
  methods: {
    goTo() {
      //需要判断是返回安卓还是页面之间跳转
      this.$router.push({ path: this.path ? this.path : "/" });
    },
  },
};
</script>

<style lang="less" scoped>
.placehold {
  height: 40px;
}
.title {
  display: flex;
  height: 44px;
  align-items: center;
  position: fixed;
  top: 0;
  background: #fafafa;
  width: 343px;
  z-index: 100;
  .back {
    height: 18px;
    width: 18px;
    img {
      width: 100%;
    }
  }
  .text {
    margin-left: 5px;
    height: 30px;
    font-size: 20px;
    font-family: Roboto-Bold, Roboto, Noto Sans, SF UI Text-Bold, SF UI Text,
      Helvetica;
    font-weight: bold;
    color: #121212;
    line-height: 30px;
  }
}
</style>
