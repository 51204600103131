/**
 * host主播模块接口列表
 */

import base from "./base"; // 导入接口域名列表
import axios from "../http"; // 导入http中创建的axios实例
// import QS from "qs"; // 根据需求是否导入qs模块

const pay = {
  // 获取用户信息
  getUser(userName) {
    return axios.get(
      `${base.baseUrl}/auth-service/open/thirdPay/user/${userName}`
    );
  },
  // 获取金币包
  getPackage(countryAb, userName) {
    return axios.get(
      `${base.baseUrl}/auth-service/open/thirdPay/package/${countryAb}/${userName}`
    );
  },
  // 创建支付订单
  buyCoin(params) {
    return axios.post(
      `${base.baseUrl}/auth-service/open/thirdPay/buyCoin`,
      params
    );
  },
  // 获取支付订单状态
  getOrderStatus(orderId, thirdOrder) {
    return axios.get(
      `${base.baseUrl}/auth-service/open/thirdPay/queryOrderStatus/${orderId}/${thirdOrder}`
    );
  },
  // 获取历史订单
  getHistoryOrder(params) {
    return axios.get(
      `${base.baseUrl}/auth-service/open/thirdPay/queryThirdOrderHistory`,
      { params }
    );
  }
};

export default pay;
