/**
 * axios封装
 * 请求拦截、响应拦截、错误统一处理
 */
import axios from "axios";
import router from "../router";
import Cookies from 'js-cookie'
// import store from "../store/index";
import { Toast } from "vant";

/**
 * 提示函数
 * 禁止点击蒙层、显示一秒后关闭
 */
const tip = msg => {
  Toast({
    message: msg,
    duration: 1000,
    forbidClick: true
  });
};

/**
 * 跳转登录页
 * 携带当前页面路由，以期在登录页面完成登录后返回当前页面
 */
const toLogin = () => {
  router.replace({
    path: "/login",
    query: {
      redirect: router.currentRoute.fullPath
    }
  });
};

/**
 * 请求失败后的错误统一处理
 * @param {Number} status 请求失败的状态码
 */
const errorHandle = (status, other) => {
  // 状态码判断
  switch (status) {
    // 401: 未登录状态，跳转登录页
    case 401:
      toLogin();
      break;
    // 403 token过期
    // 清除token并跳转登录页
    case 403:
      tip("登录过期，请重新登录");
      // localStorage.removeItem("token");
      // store.commit("loginSuccess", null);
      setTimeout(() => {
        toLogin();
      }, 1000);
      break;
    // 404请求不存在
    case 404:
      tip("请求的资源不存在");
      break;
    default:
      console.log(other);
  }
};

// 创建axios实例
var instance = axios.create({ timeout: 1000 * 30 });
// 设置post请求头
instance.defaults.headers.post["Content-Type"] =
  "application/x-www-form-urlencoded";
// 获取app用户信息
/**
 * 请求拦截器
 * 每次请求前，如果存在token则在请求头中携带token
 */

let userInfo = null;
// IOS获取用户信息
let iosUserInfo = Cookies.get('userInfo')
if(iosUserInfo){
  userInfo = iosUserInfo;
}
// 安卓获取用户信息
let androidUserInfo = window?.luck?.getInfo(); //返回的数据格式是字符串类型
if(androidUserInfo){
  userInfo = androidUserInfo;
}
console.log("userInfo设置前", userInfo);
if (!userInfo) {
  if (process.env.NODE_ENV === "development") {
    userInfo = {
      // language: "zh",
      // appId: "LUCKLUCK",
      // platform: "H5_WEB",
      // deviceIdThird: "G5rrn5ADjAFFCRBEVQfAgHxG54eIX3yO",
      // token:
      //   "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzUxMiJ9.eyJuYW1lIjpudWxsLCJhdmF0YXJJZCI6bnVsbCwidXNlcm5hbWUiOm51bGwsInBhc3N3b3JkIjpudWxsLCJhcHBsaWNhdGlvbklkIjpudWxsLCJ0ZW5hbnRJZCI6bnVsbCwidG9rZW4iOm51bGwsInJvbGVJZHMiOm51bGwsImlkIjoxNjYyNzQ4OTEyMzkzNTQ3Nzc3LCJjcmVhdGVUaW1lIjpudWxsLCJzdWIiOiIxNjYyNzQ4OTEyMzkzNTQ3Nzc3IiwiaWF0IjoxNjg1MjY4NDc2LCJleHAiOjE2OTMwNDQ0NzZ9.7S5S7xMDy88JFckCihpykIZJY5a3ti8a8l-ef48__vZg9btNM6Lj-qVuhjSRj2JxSbEuoFg-NPFWico8SNeweQ"
      // deviceIdLocal: "88091DF22552871A5363CA6FCD5DDE50",
      // "set-header-action": "dev",
    };
  } else {
    userInfo = {
      // appId: "LUCKLUCK",
      // platform: "H5_WEB"
      // deviceIdThird: "not_get_deviceIdThird",
      // deviceIdLocal: "not_get_deviceIdLocal",
      // token: "not_get_token",
      // "set-header-action": "fail"
    };
  }
  userInfo = JSON.stringify(userInfo);
}
// console.log("userInfo设置后", userInfo);
userInfo = JSON.parse(userInfo);
// console.log("userInfo转JSON", userInfo);
instance.interceptors.request.use(
  config => {
    // 登录流程控制中，根据本地是否存在token判断用户的登录情况
    // 但是即使token存在，也有可能token是过期的，所以在每次的请求头中携带token
    // 后台根据携带的token判断用户的登录情况，并返回给我们对应的状态码
    // 而后我们可以在响应拦截器中，根据状态码进行一些统一的操作。
    // const token = store.state.token;
    // token && (config.headers.Authorization = token);
    config.headers = {
      "ly-from-app-id": userInfo?.appId,
      Authorization: userInfo?.token,
      "ly-from-platform": userInfo?.platform,
      "ly-device-id-third": userInfo?.deviceIdThird,
      "ly-device-id-origin": userInfo?.deviceIdLocal,
    };
    return config;
  },
  error => Promise.error(error)
);

// 响应拦截器
instance.interceptors.response.use(
  // 请求成功
  res => (res.status === 200 ? Promise.resolve(res.data) : Promise.reject(res)),
  // 请求失败
  error => {
    const { response } = error;
    if (response) {
      // 请求已发出，但是不在2xx的范围
      errorHandle(response.status, response.data.message);
      return Promise.reject(response);
    } else {
      // 处理断网的情况
      // eg:请求超时或断网时，更新state的network状态
      // network状态在app.vue中控制着一个全局的断网提示组件的显示隐藏
      // 关于断网组件中的刷新重新获取数据，会在断网组件中说明
      if (!window.navigator.onLine) {
        console.log("网络中断");
        // store.commit("changeNetwork", false);
      } else {
        return Promise.reject(error);
      }
    }
  }
);

export default instance;
