export default {
  general: {
    backAndTitle: "{title}"
  },
  "The network is abnormal, please check the network condition":
    "网络异常，请检查网络情况",
  "Service error, please try again later": "服务异常，请稍后重试",
  "Network Error": "网络错误",
  "Facebook login": "Facebook登录",
  "Google login": "Google登录",
  "By registering, you agree to our ##User Agreement##and ##Privacy Policy##":
    "注册即代表同意我们的##《用户协议》##和##《隐私条款》##",
  "Sign in with Apple": "Apple登录",
  "requesting...": "请求中..",
  "logging in...": "登录中..",
  "Login successful": "登录成功",
  "Login failed": "登录失败",
  "complete material": "完善资料",
  "Nick name": "昵称",
  Birthday: "生日",
  gender: "性别",
  "Contains prohibited words, please re-write": "含有违禁词汇，请重新输入",
  "After gender selection, subsequent changes cannot be made":
    "性别选择后后续无法更改",
  understood: "知道了",
  save: "保存",
  Sure: "确定",
  "The platform is only available to users over the age of 18, see ##'User Agreement'##":
    "平台仅限年满18岁的用户使用，详见##《用户协议》##",
  "Select date of birth": "选择出生日期",
  "Please enter a nickname": "请输入昵称",
  "Please select your gender": "请选择性别",
  "Your account is logged in on another device. If it is not your operation, please change your account password as soon as possible!":
    "你的账号在另一设备登录，如果不是你本人操作，请尽快修改登录账号密码！",
  "last login": "上次登录",
  "The login information has expired, please log in again~":
    "登录信息已失效，请重新登录~",
  "The current account login failed, if you have any questions, please contact customer service":
    "当前账号登录失败，如有疑问请联系客服",
  feedback: "问题反馈",
  "ID copied": "ID已复制",
  "buy coins": "购买金币",
  "likes received": "收到的赞",
  "Anchor Center": "主播中心",
  "Modify data": "修改资料",
  avatar: "头像",
  preview: "预览",
  "personal introduction": "个人介绍",
  "Introduce yourself~": "介绍一下自己吧~",
  "change username": "修改昵称",
  "Please select date of birth": "请选择出生日期",
  "Modify personal introduction": "修改个人介绍",
  "Enter nickname..": "输入昵称..",
  "Gender cannot be changed!": "性别无法修改！",
  "replace photo": "替换照片",
  "delete photo": "删除照片",
  Cancel: "取消",
  "Your photo has been uploaded successfully!": "你的照片已经上传成功！",
  "Your photo upload failed, please click to try again!":
    "你的照片上传失败，请点击重试！",
  "all photos": "所有照片",
  use: "使用",
  "Album selection": "相册选择",
  Photograph: "拍照",
  "The avatar you edited at #time# has not passed the review, please upload again":
    "您于#time#修改的头像未通过审核，重新上传",
  "The photo you added at #time# has not been reviewed, please re-upload":
    "您于#time#添加的照片未通过审核，重新上传",
  "Upload your real avatar and improve your personal information to get more exposure opportunities~":
    "上传真实头像，完善您的个人信息，可以获得更多曝光机会哦~",
  "View now": "立即查看",
  "The new avatar you modified has taken effect, I wish you a happy use~":
    "您修改的新头像已生效，祝您使用愉快~",
  "The new photo you modified has taken effect, I wish you a happy use~":
    "您修改的新照片已生效，祝您使用愉快~",
  "Login failed, the account and the device have been banned!":
    "登录失败，该账号和该设备已被封禁！",
  "Login failed, the device has been banned!": "登录失败，该设备已被封禁！",
  "Login failed, the account has been banned!": "登录失败，该账号已被封禁！",
  appeal: "申诉",
  Notice: "通知",
  "blur effect": "模糊效果",
  "about us": "关于我们",
  "new version update": "版本更新",
  "Help and Feedback": "帮助与反馈",
  "sign out": "退出登录",
  "message notification": "消息提醒",
  "incoming call": "来电提醒",
  "Friends online reminder": "好友上线提醒",
  "Privacy Policy": "隐私条款",
  "User Agreement": "用户协议",
  "delete account": "删除账号",
  "version number": "版本号",
  "Are you sure you want to log out?": "确定要退出登录？",
  blacklist: "黑名单",
  remove: "移除",
  "Are you sure you want to remove this user from the blacklist?":
    "确定要将该用户移除黑名单？",

  "Removed successfully": "移除成功",
  "removal failed": "移除失败",
  "Are you sure you want to delete your account?": "是否确定要删除你的账户？",
  "please tell us why you left": "请告诉我们你离开的原因",
  harassed: "被骚扰",
  "worry about privacy": "担心隐私",
  "Frequent notifications": "通知频繁",
  other: "其他",
  "other reasons": "其他原因",
  "Please tell us why you deleted your account": "请告诉我们删除账号的原因",
  Precautions: "注意事项",
  "If you delete your account, all user data such as your purchase history, gold coins, and friend list will be permanently deleted.":
    "如果删除账号，将永久删除你的购买记录、金币、好友列表等所有用户数据，",
  "Once deleted, this data cannot be recovered!":
    "这些数据一旦删除便无法恢复！",
  "I understand and agree that all account data above will be permanently deleted.":
    "我已了解同同意上述所有账号数据都将永久删除。",
  "to chat with": "聊天",
  information: "消息",
  "my good friend": "我的好友",
  "search for friends": "搜索好友",
  collect: "收藏",
  friend: "好友",
  "Customer Service": "客服中心",
  "official news": "官方消息",
  "official assistant": "官方助手",
  "LuckLuck Team": "LuckLuck Team",
  "meet new friends": "遇见新朋友",
  search: "搜索",
  "No friends found": "未搜索到对应好友",
  "Online reminder": "上线提醒",
  unfavorite: "取消收藏",
  "set note name": "设置备注名",
  report: "举报",
  "pull black": "拉黑",
  delete: "删除",
  "Online reminder is on": "上线提醒已开启",
  "Online reminder is off": "上线提醒已关闭",
  "Enter a note name": "输入备注名",
  "delete friend": "删除好友",
  "Friends online notification": "好友上线通知",
  "opened number": "开启人数",
  "Turn on a friend's online reminder and follow her at any time":
    "开启好友上线提醒，随时关注她",
  "Turn on a friend's online reminder and follow him at any time":
    "开启好友上线提醒，随时关注他",
  "Here you can set friend online reminder": "这里可以设置好友上线提醒",
  "Private letter": "私信",
  "Video chat": "视频聊天",
  "Adding friends and starting a chat will cost xx gold":
    "添加好友并发起聊天将花费xx金币",
  "Insufficient gold coins, adding friends and starting a chat will consume xx gold coins":
    "金币不足，添加好友并发起聊天需消耗xx金币",
  "Confirm add": "确认添加",
  "to recharge": "去充值",
  "After blacklisting, the friendship between the two parties will be canceled, and you will no longer receive any messages from the other party. You can cancel it in 'Settings - Blacklist'":
    "拉黑后将解除双方的好友关系且不再收到对方发来的任何消息，可在'设置-黑名单'内解除",
  "Blocked successfully": "拉黑成功",
  "Failed to pull black": "拉黑失败",
  "Failed to send, you have blocked the other party, you can continue chatting after unblocking":
    "发送失败，你已拉黑对方，解除拉黑后可继续聊天",
  "Failed to send, please add the other party as a friend first":
    "发送失败，请先添加对方为好友",
  "Are you sure you want to delete this friend? Chat history will be cleared after deleting friends":
    "确定要删除该好友？删除好友后将清除聊天记录",
  "Delete friends successfully": "删除好友成功",
  "Failed to delete friend": "删除好友失败",
  "[picture]": "[图片]",
  "[Gift]": "[礼物]",
  "[Telephone]": "[电话]",
  "[video]": "[视频]",
  "#UserName# gived you a gift": "#UserName#赠送您一个礼物",
  "system notification": "系统通知",
  "Earnings notice": "收益通知",
  "Say Hi message": "Say Hi消息",
  "delete this chat": "删除该聊天",

  "Are you sure you want to delete all chats with #USERNAME#?":
    "确定要删除与#USERNAME#的全部聊天记录？",
  "confirm deletion": "确认删除",
  "Hi, welcome to #AppName#, where you can meet a lot of fun and interesting new friends, I wish you a happy time here~":
    "Hi，欢迎来到#AppName#，在这里你可以遇到很多好玩有趣的新朋友，祝你在这里玩的开心~",
  "xx greeting messages received": "已收到xx个招呼消息",
  "There are 17 new greeting messages": "有17个新招呼消息",
  "I haven't received the greeting message yet, hurry up to match and meet more people":
    "还没有收到招呼消息，赶快去匹配认识更多人吧",
  "go to match": "前往匹配",
  "Are you sure you want to set all hello messages as read, the unread message prompt will be cleared, and the list data will not be cleared":
    "确定要将所有打招呼消息设为已读吗，将清除未读消息提示，不会清空列表数据",
  "all read": "全部已读",
  "All messages unread hello message is set to read":
    "所有消息未读打招呼消息已设为已读",
  "No unread messages yet": "暂无未读消息",
  "#USERNAME# would like to be friends with you": "#USERNAME#希望与你成为好友",
  ignore: "忽略",
  "#USERNAME# has sent you a friend invitation": "#USERNAME#向您发出了好友邀请",
  "You are not friends yet, add friends to unlock more features":
    "你们还不是好友，添加好友后可解锁更多功能",
  "add friend": "添加好友",
  "You and #USERNAME# have become friends, let's chat together~":
    "您和#USERNAME#已经成为好友啦，一起来聊天吧~",
  "You have initiated a friend request to #USERNAME#":
    "您已向#USERNAME#发起了好友请求",
  "We are already friends, let's chat together~":
    "我们已经是好友啦，一起聊天吧~",
  "Failed to send, the other party can only continue to send after replying to the message or becoming a friend":
    "发送失败，对方回复消息或成为好友后才能继续发送",
  "Failed to send the message, do you want to resend it?":
    "消息发送失败，是否重发？",
  Resend: "重发",
  copy: "复制",
  "Only if both parties are friends can make a video call":
    "双方为好友才能视频电话",
  "You can only send pictures when the other party replies to you or is a friend":
    "对方回复您或好友关系下才能发送图片",
  "Prohibited words detected, this message has been blocked":
    "检测到违禁词汇，系统已屏蔽此条消息",
  "online status": "在线状态",
  "turn on": "开启",
  closure: "关闭",
  "Your online status is on, you will get a lot of video calls and exposure opportunities~":
    "您的在线状态为开启，将获得超多视频来电及曝光机会~",
  "Your online status is off, which will reduce calls and suspend your exposure!":
    "您的在线状态为关闭，将减少来电且暂停你的曝光机会！",
  "Online Status Description": "在线状态说明",
  "Whether the online switch is your work status switch, if you start to work, please turn on the work switch first, so that you will get a high exposure opportunity and a lot of video calls. If you can turn off the work switch when you are about to stop working, the platform will reduce your exposure opportunities~":
    "在线开关是否您的工作状态开关，如果你开始工作时请先打开工作开关，这样你将获得超高的曝光机会和很多的视频来电。如果您将要停止工作时可以关闭工作开关，平台将减少你的曝光机会~",
  "my earnings": "我的收益",

  "View Income Report": "查看收入报告",
  "Anchor strategy": "主播攻略",
  "video phone price": "视频电话价格",
  "Friend Video Call Price": "好友视频电话价格",
  "Super Video Call Price": "非好友视频电话价格",
  "Level and exchange rate": "等级及兑换比例",
  "Subscription ratio": "兑换比例",
  "last week grade": "上周等级",
  "Grade of the week": "本周等级",
  "Withdrawal account": "提现账户",
  "Bind the withdrawal account": "绑定提现账户",
  bound: "已绑定",
  unbound: "未绑定",
  "Encounter problems?": "遇到问题？",
  "Immediate Feedback": "立即反馈",
  "price interpretation": "价格解读",
  "Please avoid violations during the video": "请避免在视频过程中出现违规行为",
  "The average call time is short, please pay attention to increase the call time with other users and increase the answering rate of video calls;":
    "平均通话时长较短，请注意提升同其他用户的通话时间，以及提高视频来电的接听率；",
  "This feature is not yet open, so stay tuned~": "该功能暂未开放，敬请期待~",
  "income report": "收入报告",
  "Data is calculated according to UTC+0 time": "数据根据UTC+0时间计算",
  "Earnings today": "今日收益",
  DAILY: "今日收益",
  "Earnings this week": "本周收益",
  WEEKLY: "本周收益",
  "Half month income": "半月收益",
  "Earnings this month": "本月收益",
  "income details": "收益明细",
  today: "今日",
  "this week": "本周",
  "half a month": "半个月",
  "this month": "本月",
  total: "合计",
  "Video telephony revenue": "视频电话收益",
  "Gift proceeds": "礼物收益",
  "Active Task Earnings": "活跃任务收益",
  "xx minutes xx seconds": "xx分xx秒",
  "xx gold coins/minute": "xx金币/分钟",
  "Due to the price adjustment of the platform, the price of your video has been automatically adjusted to xx gold coins/minute":
    "因平台价格调整，你的视频价格已自动调整为xx金币/分钟",
  "Having trouble recharging? ##Contact Customer Service>##":
    "充值遇到问题？##联系客服>##",
  "Questions about your order? ##Contact Customer Service##":
    "订单有疑问？##联系客服##",
  "Failed to get coin pack, please try again later":
    "金币包获取失败，请稍后重试",
  Retry: "重试",
  "Copy order number": "复制订单号",
  "buy goods": "购买商品",
  "Only show orders from the last 30 days": "仅显示最近30天的订单记录",
  "order number": "订单号",
  "order time": "下单时间",
  fail: "失败",
  success: "成功",
  processing: "处理中",
  "Buying, please don't leave...": "正在购买，请不要离开…",
  "successful recharge": "充值成功",
  "Recharge failed": "充值失败",
  "Order number copied": "订单号已复制",
  "Free Gold Rewards": "免费金币奖励",
  "Congratulations on getting 50 free coins": "恭喜你获得50免费金币",
  "Get it for free": "免费领取",
  "Congratulations, you have received xx gold coins for free":
    "恭喜你已免费领取xx金币",
  "Buy now": "立即购买",
  "Tap the screen to start pairing": "点击屏幕开始配对",
  all: "全部",
  male: "男性",
  female: "女性",
  free: "免费",
  "gender preference": "性别偏好",
  recharge: "充值",
  "The balance of gold coins is insufficient, please recharge first~":
    "金币余额不足，请先充值~",
  "In order to create a green and healthy video chat atmosphere and live up to the good times, please respect each other and chat in a civilized manner. The following violations are strictly prohibited during the video process":
    "为了营造绿色健康的视频聊天氛围，不负美好时光，请大家相互尊重文明聊天，视频过程中严禁出现以下违规行为",
  "No exposure of private parts, nudity, no clothing or very little clothing":
    "禁止暴露隐私部位，裸体、不穿衣服或穿的很少",
  "Dirty language, violent behavior, dangerous behavior":
    "脏话、暴力行为、危险行为",
  "New friends are coming at the speed of light, please don't quit early~":
    "新朋友正在光速赶来，请不要提前退出哦~",
  "Violations in the video will trigger a feature ban~":
    "视频中出现违规行为将触发功能封禁哦~",
  "It's better to chat, you can add friends~": "聊的比较好可以添加好友哦~",
  "Video connecting...": "视频连接中…",
  connecting: "连接中",
  Next: "下一个",
  "The current gold coins are insufficient, continuing to match will consume xx gold coins/time":
    "当前金币不足，继续匹配将消耗xx金币/次",
  "gold coin recharge": "金币充值",
  "Are you sure you want to quit matching": "确定要退出匹配吗",
  quit: "退出",
  "Any bad behavior is not allowed in the video chat. If you find any illegal behavior, you can choose to report or block the other party!":
    "视频聊天中不 · 允许有任何不良行为，如发现违规行为可选择举报或拉黑对方！",
  "Select the reason for reporting": "选择举报原因",
  "Thank you for your report, we will deal with it as soon as possible":
    "感谢您的举报，我们会尽快处理",
  "Report failed, please try again later": "举报失败，请稍后重试",
  "sexual nudity": "色情裸露行为",
  "fake sex": "假性别",
  underage: "未成年",
  "Violent and bloody": "暴力血腥",
  "Terrorism and politics": "涉恐涉政",
  "Swipe left to switch users": "向左滑动切换用户",
  "Please show your face for video chat": "请漏出你的面部进行视频聊天",
  "The blur will disappear when #username# faces the camera":
    "等#username#面对镜头时模糊效果就会消失",
  "remove blur effect": "移除模糊效果",
  "you just sent a like": "你刚刚发送了一个赞",
  "The other person gave you a like": "对方给你点了个赞",
  "friend request sent": "好友请求已发出",
  "The other party requested to add you as a friend": "对方请求添加你为好友",
  agree: "同意",
  "congratulations you have become friends": "恭喜你们已成为好友",
  "You can hang up after the countdown is over": "你可以在倒计时结束之后挂断",
  beauty: "美颜",
  whitening: "美白",
  "Abrade skin": "磨皮",
  "video match": "视频匹配",
  match: "匹配",
  "match history": "匹配历史",
  duration: "时长",
  "No matching history": "暂无历史匹配记录",
  "start matching": "开始匹配",
  "You have spent xx gold coins to send a friend request successfully":
    "你已消耗xx金币成功发送好友请求",
  "Turn on gender filter": "开启性别筛选",
  "xx gold coins/time": "xx金币/次",
  "Do you want to turn on gender filtering to continue matching girls?":
    "是否开启性别筛选继续匹配女孩？",
  "Turning on gender screening and matching will cost xx gold coins/time. The current gold coin balance is insufficient, please recharge first!":
    "开启性别筛选匹配将花费xx金币/次，当前金币余额不足，请先充值！",
  "Which gender do you wish to match to?": "你希望匹配到那种性别？",
  "Screening for gender matching will cost xx gold/time":
    "筛选性别匹配将花费xx金币/次",
  "There is no record of likes, go match to get more likes~":
    "暂无获赞记录，快去匹配获得更多点赞吧~",
  "There are xx opportunities for free video calls per hour, and it will cost xxx gold coins/time to continue to make calls beyond the number of times":
    "每小时有xx次免费视频呼叫的机会，超过次数继续拨打需花费xxx金币/次",
  "Video chat will cost xx [gold coins]/minute, less than 1 minute will be counted as 1 minute":
    "视频聊天需花费xx【金币】/分钟，不足1分钟按1分钟计算",
  "Video chat will cost xx [gold coins]/minute, if it is less than 1 minute, it will be counted as 1 minute. Your balance is insufficient, please recharge~":
    "视频聊天需花费xx【金币】/分钟，不足1分钟按1分钟计算，您的余额不足，请充值~",
  "The other party has set the call fee": "对方设置了通话费用",
  "call charges": "通话费用",
  "call cancelled": "呼叫已取消",
  Cancelled: "已取消",
  "The other party has canceled": "对方已取消",
  "Missed call": "未接来电",
  "The other party is temporarily unavailable": "对方暂时无法接通",
  "The other party does not answer": "对方无应答",
  "call rejected": "呼叫已拒绝",
  rejected: "已拒绝",
  "call ended": "通话结束",
  "The other party has hung up and the call is over": "对方已挂断，通话结束",
  "video price": "视频价格",
  price: "价格",
  "connecting…": "连接中…",
  "Waiting for the other party to answer...": "等待对方接听…",
  "Insufficient gold coin balance, please recharge": "金币余额不足，请充值",
  "Remaining time: XXs": "剩余时长：XXs",
  "Click to call back": "点击回拨",
  "call duration": "通话时长",
  "Call revenue": "来电收益",
  "invite you to a video call": "邀请你视频通话",
  "video call": "视频来电",
  "missed call": "未接呼叫",
  "Your video call with ##username## has ended, get xx gold coins~":
    "您与##username##的视频通话已结束，获得xx金币~",
  "LuckLuck Club": "LuckLuck  Club",
  recommend: "推荐",
  "up to date": "最新",
  Turkish: "土耳其语",
  Arabic: "阿语",
  English: "英语",
  online: "在线",
  "busy line": "忙线",
  offline: "离线",
  "Failed to get data, please refresh and try again":
    "数据获取失败，请刷新重试",
  "Matching degree: xx%": "匹配度：xx%",
  answer: "接听",
  "Screenshots cannot be taken due to security policy restrictions":
    "由于安全政策的限制，无法截取屏幕截图",
  "The system detects that the other party is using a screen capture or screen recording tool, please pay attention to regulating personal behavior!":
    "系统检测到对方正在使用截屏或录屏工具，请注意规范个人行为！",
  "Failed to send, the picture contains illegal sensitive information":
    "发送失败，图片包含违规敏感信息",
  "Violation warning": "违规警告",
  "If you are not a friend, any pornography or nudity is not allowed. If you violate this rule again, your account functionality will be banned.":
    "如果你不是好友，任何色情或裸体都是不允许的。如果您再次违反此规则，您的帐户功能将被封禁。",
  "Restricted use": "限制使用",
  "We're sorry, we've received reports from users of 'sexual or nudity' in your video chat. After the platform has verified that there is indeed a violation, the [xx function] will be temporarily frozen. Continued pornography or nudity will prolong the freeze time.":
    "很抱歉，我们收到用户举报您在视频聊天中有'色情行为或裸露行为'。经过平台核验确实存在违规行为，【xx功能】将暂时冻结。持续的色情行为或裸露将延长冻结时间。",
  "Reporting time": "举报时间",
  "limit time": "限制时间",
  unlock: "解锁",
  "Ban Unlock": "封禁解锁",
  "Unlocking [xx function] will cost xx gold coins":
    "解锁【xx功能】将花费xx金币",
  "Unlocking [matching function] will cost xx gold coins, the balance is insufficient, please recharge first~":
    "解锁【匹配功能】将花费xx金币，余额不足，请先充值~",
  "Unlocked successfully, you can use the xx function":
    "解锁成功，您可以使用xx功能",
  "Hello, because the platform detects that your gender does not match, it has been automatically changed to 'male/female'. If you have any questions, please contact customer service for feedback~":
    "您好，由于平台检测到你的性别不符，已自动修改为'男/女性'，如有疑问可联系客服反馈~",
  "There are only 10 free translations per day.": "每天仅有10次免费翻译次数。",
  "The translation function is enabled, you can chat freely~":
    "翻译功能已开启，你可以无障碍畅聊啦~",
  "Translation limit reached": "翻译已达限制",
  "There are only xx free translations per day, recharge any amount to unlock the translation function":
    "每日仅有xx次免费翻译，充值任意金额即可解锁翻译功能",
  "new version discovered": "发现新版本",
  "The new version of xxxx has been released, hurry up and update the experience~":
    "新版本xxxx已发布，赶快更新体验吧~",
  "download update": "下载更新",
  "Try it now": "立即体验",
  "install new version": "安装新版本",
  "Downloading Apk file...": "Apk文件下载中...",
  "install now": "立即安装",
  "Apk file size: xxxM": "Apk文件大小：xxxM",
  "The update package is downloading in the background...":
    "更新包后台下载中...",
  "The new version of xxxxAPK package has been downloaded, please install and experience it":
    "新版本xxxxAPK包已下载完成，赶快安装体验吧",
  "Currently the latest version": "当前已是最新版本",
  Licensing: "授权许可",
  "Video chat needs to open the following permissions":
    "视频聊天需要开放以下权限",
  camera: "相机",
  "video call, take photo": "视频通话，拍摄照片",
  microphone: "麦克风",
  "Output sound during video call": "视频通话时输出声音",
  "It is detected that some of your functions are not enabled, and the following permissions need to be opened for video chat":
    "检测到你的部分功能未开启，进行视频聊天需要开放以下权限",
  "In order to better answer the phone and get more exposure opportunities, please allow to stop optimizing the usage of the store site":
    "为了你可以更好的接听电话和获得更多曝光机会，请允许停止优化优化店址使用量",
  allow: "允许",
  "not allowed": "不允许",
  "Manual customer service": "人工客服",
  "contact us": "联系我们",
  "If you have any questions, please contact customer service":
    "如有疑问可咨询客服",
  "new news": "新消息",
  "new greeting message": "新招呼消息",
  "Your friend [#UserName] is online, start chatting now~":
    "你的好友【#UserName】上线啦，赶快开始聊天吧~",
  "Click to start a private chat now >>": "点击立即开始私聊>>",
  "invites you to a video call": "邀请你进行视频通话",
  "Turn on the message notification and never miss your friend's private message and call notification again~":
    "打开消息通知，再也不错过好友的私信消息和来电通知啦~",
  "Turn on message notification permission": "打开消息通知权限",
  "Get timely notifications from friends and incoming calls":
    "及时获取好友消息和来电通知",
  "Enable message notification and online reminder": "开启消息通知及上线提醒",
  "To receive online alerts, you need to turn on the system push alerts and online alerts":
    "想要收到Ta的上线提醒需打开系统推送提醒和上线提醒开关",
  "Turn on message notifications": "开启消息通知",
  "If you want to receive the online reminder of Ta, you need to open the system push reminder":
    "想要收到Ta的上线提醒需打开系统推送提醒",
  "After closing, you will not be able to receive video call notifications from friends":
    "关闭后你将无法接收到好友的视频来电通知",
  "Confirm to close": "确认关闭",
  "": "",
  "Please enter bank account number": "请输入银行账号",
  "Bank Account Number (IBAN)": "银行账号（IBAN）",
  "account name": "账户名",
  "please enter your username": "请输入账户名",
  "The format of the bank account number is incorrect, please re-enter":
    "银行账号格式错误，请重新输入",
  "Format error, please re-enter": "格式错误，请重新输入",
  "First Name": "名",
  "Last Name": "姓",
  "SWIFT number": "SWIFT号",
  "Bank name": "银行名称",
  "bank account name": "银行账户名",
  "Please enter bank account name": "请输入银行账户名",
  "Please enter bank name": "请输入银行名称",
  "Full Name": "Full Name",
  "Papara account name": "Papara账户名",
  "*The account name can be the registered Papara mobile number, email or Papara number":
    "*账户名可以为注册Papara的手机号码，电子邮件或账户ID",
  "Don't have a Papara account yet? Go to Register Papara >":
    "还没有Papara账号？前往注册Papara>",
  Papara: "Papara号",
  "cellphone number": "手机号码",
  "e-mail": "电子邮件",
  "Your user ID": "你的用户账号",
  "Personal information": "个人信息",
  "Bank information": "银行信息",
  "Please enter your first name": "请输入名字",
  "Please enter your last name": "请输入姓氏",
  "Please enter SWIFT number": "请输入 SWIFT 号码",
  "Confirm submission": "确认提交",
  "Back to modify": "返回修改",
  "Modify information": "修改信息",
  "Can only contain letters, numbers, spaces, dashes (-), dots (.)":
    "只能包含英文、数字、空格、中划线(-)、点(.)",
  "The value must start with TR plus 24 digits": "值必须是TR开头加24位数字",
  "Please enter Papara number": "请输入 Papara 号",
  "Please enter Papara account name": "请输入 Papara 账户名",
  "Bank account number must be entered": "必须输入银行账号",
  "Bank account name must be entered": "必须输入银行账户名",
  "Bank name is required": "必须输入银行名称",
  "SWIFT number must be entered": "必须输入SWIFT号",
  "First name is required": "必须输入名",
  "Last name is required": "必须输入姓",
  "Papara number must be entered": "必须输入papara号",
  "Papara account name must be entered": "必须输入papara账户名",
  "Please check carefully to ensure that the information is correct, otherwise the transfer will not be possible.":
    "请仔细核对，确保信息无误，否则无法转账",
  MATCH_VIDEO: "匹配视频",
  PRIVATE_MESSAGE: "私信",
  FRIEND_VIDEO_CALL: "好友视频电话",
  STRANGER_VIDEO_CALL: "非好友视频电话",
  "Account type": "账户类型",
  "Click to select the account type": "点击选择账户类型",
  "Account ID": "账户ID",
  "The phone": "电话",
  email: "邮箱",
  ID: "账户ID",
  PHONE: "电话",
  EMAIL: "邮箱",
  "Account type must be entered": "账户类型必须选择",
  "No Papara": "没有Papara账号",
  "Go to Register": "立即去注册",
  Street: "街道",
  "Please enter street": "请输入街道",
  "Street must be entered": "必输入街道",
  City: "城市",
  "Please enter city": "请输入城市",
  "City must be entered": "必输入城市",
  Province: "省份",
  "Please enter province": "请输入省份",
  "Province must be entered": "必输入省份",
  Postcode: "邮编",
  "Please enter postcode": "请输入邮编",
  "Postcode must be entered": "必输入邮编",
  // 购买金币
  // "Chinese (Simplified)": "简体中文",
  // English: "英文",
  // Turkish: "土耳其语",
  "Log in": "登录",
  "Login successfully": "登录成功",
  "Login failed, please enter a valid LuckLuck ID~":
    "登录失败，请输入有效的LuckLuck ID 哦～",
  "Enter LuckLuck ID to log in and recharge,": "输入LuckLuck ID 登录后储值，",
  "Previous order": "历史订单",
  "Select country/region": "选择国家/地区",
  Global: "全球",
  Turkey: "土耳其",
  "Select the number of gold coins": "选择金币数量",
  "Select the payment method": "选择付款方式",
  "This amount is not currently supported": "暂不支持该额度",
  "Online  service": "在线客服",
  "LuckLuck official top-up platform to ensure your information security":
    "LuckLuck官方充值平台，保障您的信息安全",
  "Enter LuckLuck ID": "输入LuckLuck ID",
  "Add other account": "其他账号",
  "Enter UID": "输入UID",
  "Enter LuckLuck ID to log in and recharge": "输入LuckLuck ID 登录后储值",
  "How to get LuckLuck ID？": "如何获取LuckLuck ID？",
  "Login area (not logged in)": "登录区（未登录状态）",
  "Login area (logged in-multi-account switching)":
    "登录区（已登录-多账号切换）",
  "Need help?": "支付遇到问题？",
  "Go to help center": "立即联系客服",
  "help center": "联系客服",
  "Recharge 60 gold coins": "充值60金币",
  Recharging: "充值中",
  "Recharge successful": "充值成功",
  // "Recharge failed":"充值失败",
  "Recharge record": "充值记录",
  Copy: "复制",
  "Payment amount": "支付金额",
  "Payment platform": "支付平台",
  "Platform order number": "平台订单号",
  time: "时间",
  "Recharge account": "充值账号",
  "Recharge amount": "充值金额",
  "Recharge gold coins": "充值金币",
  "Recharge order number": "充值订单号",
  "Recharge time": "充值时间",
  "Please log in to the app to check the specific gold coins transfer status. If you have any questions, please contact the official customer service":
    "具体金币到账情况请登录app内进行核对，如有问题可联系官方客服",
  "Back to homepage": "返回首页",
  // "Processing":"处理中",
  "[Reminder to account of gold coins] The xxx gold coins you purchased have been successfully transferred to your account":
    "【金币到账提醒】您购买的xxx金币已成功到账",
  "Payment failed, this channel is temporarily unavailable, please change another payment method or try again later~":
    "支付失败，该渠道暂无法支付，请更换其他支付方式或稍后重试~",
  Ok: "知道了",
  "Historical Orders": "历史订单",
  Loading: "加载中",
  "No more": "没有更多了",
  "Copy success": "复制成功",
  "LuckLuck ID cannot be empty": "LuckLuck ID不能为空",
  "Order status": "订单状态",
  "Continue buying gold coins": "继续购买金币",
  "Exit the payment page": "退出支付页"
};
