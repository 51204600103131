<template>
  <div @click="loadPage('/AnchorCenter/PhonePrice')" class="wrap">
    <div class="price-box">
      <div class="price mb8">
        <span>{{$t(price)}}</span>
        <img v-if="!non_unit" src="@/assets/Icon-32x32-Gold@2x.png" alt="" />
        <span v-if="!non_unit" class="unit">/Min</span>
      </div>
      <span class="explain"> {{$t(explain)}}</span>
    </div>
    <img v-if="!non_view" class="view" src="@/assets/Icon-64x64-Right-Button@2x.png" alt="" />
  </div>
</template>

<script>
export default {
  name: "PhonePriceItem",
  props: ["price", "explain", "non_view", "non_unit"],
};
</script>

<style lang="less" scoped>
.wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  padding: 16px;
  min-height: 86px;
  background: #ffffff;
  border-radius: 8px 8px 8px 8px;
  opacity: 1;
  border: 1px solid #ebebeb;
  .price-box {
    min-height: 52px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .price {
      display: flex;
      align-items: center;
      height: 24px;
      font-size: 16px;
      font-family: Roboto-Bold, Roboto, Noto Sans, SF UI Text-Bold, SF UI Text,
        Helvetica;
      font-weight: bold;
      color: #121212;
      line-height: 24px;
      img {
        width: 24px;
        height: 24px;
        margin: 0 4px;
      }
    }
    .explain {
      min-height: 22px;
      font-size: 14px;
      font-family: Roboto-Bold, Roboto, Noto Sans, SF UI Text-Medium, SF UI Text,
        Helvetica;
      font-weight: 500;
      color: #666666;
      line-height: 22px;
    }
  }
  .view {
    width: 32px;
    height: 32px;
  }
}
</style>
