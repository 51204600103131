/**
 * host主播模块接口列表
 */

import base from "./base"; // 导入接口域名列表
import axios from "../http"; // 导入http中创建的axios实例
// import QS from "qs"; // 根据需求是否导入qs模块

const host = {
  // 获取收入报告
  getIncomeReport() {
    return axios.get(`${base.baseUrl}/auth-service/m/host/hostIncomeReport`);
  },
  // 获取收入详情
  getIncomeDetail(params) {
    return axios.get(
      `${base.baseUrl}/auth-service/m/host/hostIncomeDetail`,
      { params }
      // QS.stringify(params)
    );
  },
  // /auth-service/m/activity/page
  // 获取轮播图
  getSwipeImg(params) {
    return axios.get(`${base.baseUrl}/auth-service/m/activity`, {
      params
    });
  },
  // 获取电话价格
  getPhonePrice() {
    return axios.get(`${base.baseUrl}/auth-service/m/host/hostPrice`);
  },
  // 获取兑换比例，主播等级？
  getConversionRatio() {
    return axios.get(`${base.baseUrl}/auth-service/m/host/hostShareRatio`);
  },
  // 获取提现账号
  getWithdrawAccount() {
    return axios.get(`${base.baseUrl}/auth-service/m/host/bankAccount`);
  },
  // 设置提现账号
  setWithdrawAccount(params) {
    return axios.post(
      `${base.baseUrl}/auth-service/m/host/bankAccount`,
      params
    );
  },
  getHostInfo() {
    return axios.get(`${base.baseUrl}/auth-service/m/host`);
  }
  // 其他接口…………
};

export default host;
