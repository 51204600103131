<template>
  <div class="box">
    <div @click="openAIHelp()"><img src="@/assets/Icon-24x24-Feedback@2x.png" alt="" />
      <span>{{$t('help center')}}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "OnlineService",
};
</script>

<style lang="less" scoped>
.box {
  position: fixed;
  bottom: 70px;
  right: 0;
  padding: 0 10px;
  height: 40px;
  background: #39cc80;
  border-radius: 8px 0px 0px 8px;
  opacity: 1;
  div {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    text-decoration: none;
  }
  img {
    width: 24px;
    height: 24px;
    margin-right: 9px;
  }
  span {
    height: 22px;
    font-size: 14px;
    font-family: Roboto-Bold, Roboto, Noto Sans, SF UI Text-Bold, SF UI Text,
      Helvetica;
    font-weight: bold;
    color: #ffffff;
    line-height: 22px;
  }
}
</style>
