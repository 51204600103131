<template>
  <div class="box">
    <img src="@/assets/Icon-48x50-safe@2x.png" alt="">
    <span>{{$t('LuckLuck official top-up platform to ensure your information security')}}</span>
  </div>
</template>

<script>
export default {
  name: "InfoSecurity",
};
</script>

<style lang="less" scoped>
.box {
  position: fixed;
  bottom: 0;
  width: 100%;
  background: #39cc80;
  padding: 8px 16px;
  display: flex;
  align-items: center;
  img {
    width: 24px;
    height: 25px;
    margin-right: 12px;
  }
  span {
    font-size: 14px;
    font-family: Roboto-Bold, Roboto, Noto Sans, SF UI Text-Bold, SF UI Text,
      Helvetica;
    font-weight: bold;
    color: #ffffff;
    line-height: 22px;
  }
}
</style>