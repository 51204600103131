<template>
  <div class="ml16 mr16">
    <!-- <router-link to="/IncomeReport">go to IncomeReport</router-link> -->
    <BackAndTitle :path="{ target: 'closeH5' }" :title="'Anchor Center'" />
    <van-loading v-show="loading" size="24px" vertical></van-loading>
    <template v-if="showInfo">
      <span class="sub-title">{{ $t("my earnings") }}</span>
      <div
        @click="loadPage('/AnchorCenter/IncomeReport')"
        class="Earnings-period"
      >
        <div class="content">
          <div class="today">
            <span class="text">{{ $t("Earnings today") }}</span>
            <span class="value"
              ><span>+</span>{{ dailyIncome
              }}<span class="usd">[≈ ${{ dailyIncomeUsd }}]</span></span
            >
            <!-- <span class="value"
              ><span>+</span>{{ 22
              }}<span class="usd">[≈ ${{ 22 }}]</span></span
            > -->
          </div>
          <div class="line"></div>
          <div class="week">
            <span class="text">{{ $t("Earnings this week") }}</span>
            <span class="value"
              ><span>+</span>{{ weeklyIncome
              }}<span class="usd">[≈ ${{ weeklyIncomeUsd }}]</span></span
            >
          </div>
        </div>
        <div class="view">
          <span>{{ $t("View Income Report") }}</span>
          <div>
            <img src="@/assets/Icon-12x12-Go@2x.png" alt="" />
          </div>
        </div>
        <img class="bg-img" src="@/assets/My-Earnings-bg@2x.png" alt="" />
      </div>
      <span
        v-if="hasEvent"
        @click="
          createReportData({
            event_id: '17-1-1-2',
            free_name1: 'item.id',
            free_name2: '1415913917',
          })
        "
        class="sub-title"
        >{{ $t("Anchor strategy") }}</span
      >
      <div v-if="hasEvent">
        <van-Swipe :autoplay="3000">
          <van-swipe-item v-for="(item, index) in swipeArr" :key="index">
            <a class="activity-link" :href="item.activityLink">
              <img :src="item.imgUrl" :data-src="item.imgUrl" alt="" />
            </a>
          </van-swipe-item>
        </van-Swipe>
      </div>
      <span class="sub-title">{{ $t("video phone price") }}</span>
      <PhonePriceItem
        class="mb8"
        :price="strangerVideoPrice"
        :explain="'Super Video Call Price'"
      />
      <PhonePriceItem
        :price="friendVideoPrice"
        :explain="'Friend Video Call Price'"
      />
      <span class="sub-title">{{ $t("Level and exchange rate") }}</span>
      <div class="level-ratio">
        <!-- <div class="level">
        <span class="title">Grade of this week</span
        ><span class="value">NEW</span>
      </div>
      <div class="level mt16 mb16">
        <span class="title">Last week's Grades</span
        ><span class="value">NEW</span>
      </div> -->
        <div class="ratio">
          <span class="title">{{ $t("Subscription ratio") }}</span>
          <span v-if="conversionRatio" class="value"
            >{{ conversionRatio
            }}<img src="@/assets/Icon-32x32-Gold@2x.png" alt="" />=1USD</span
          >
        </div>
      </div>
      <span class="sub-title">{{ $t("Withdrawal account") }}</span>
      <WithdrawalAccount :isBind="isBind" />
      <QuickFeedback />
      <FeedbackFixed />
    </template>
  </div>
</template>

<script>
import QuickFeedback from "@/components/QuickFeedback";
import BackAndTitle from "@/components/BackAndTitle";
import PhonePriceItem from "@/components/PhonePriceItem";
import WithdrawalAccount from "@/components/WithdrawalAccount";
import FeedbackFixed from "@/components/FeedbackFixed";
export default {
  name: "AnchorCenter",
  components: {
    QuickFeedback,
    BackAndTitle,
    PhonePriceItem,
    WithdrawalAccount,
    FeedbackFixed,
  },
  data() {
    return {
      hasEvent: false,
      swipeArr: [],
      dailyIncome: "",
      weeklyIncome: "",
      friendVideoPrice: "",
      strangerVideoPrice: "",
      conversionRatio: "",
      isBind: false,
      loading: true,
      showInfo: false,
    };
  },
  methods: {
    getInfo() {
      // 获取收入报告
      this.$api.host.getIncomeReport().then((res) => {
        this.dailyIncome = res.data?.dailyIncome;
        this.weeklyIncome = res.data?.weeklyIncome;
        this.dailyIncomeUsd = res.data?.dailyIncomeUsd;
        this.weeklyIncomeUsd = res.data?.weeklyIncomeUsd;
      });
      // 获取轮播图
      this.$api.host
        .getSwipeImg({ displayView: "ANCHOR_MIDDLE" })
        .then((res) => {
          if (res.code == 0) {
            this.swipeArr = res?.data;
            this.swipeArr.forEach((element) => {
              this.createReportData({
                event_id: "17-1-1-1",
                free_name1: element.id,
              });
            });
          }
          if (res.data.length > 0) {
            this.hasEvent = true;
          }
        });

      // 获取电话价格
      this.$api.host.getPhonePrice().then((res) => {
        this.friendVideoPrice = res.data?.friendVideo;
        this.strangerVideoPrice = res.data?.strangerVideo;
      });
      // 获取兑换比例
      this.$api.host.getConversionRatio().then((res) => {
        this.conversionRatio = res.data?.exchangeRate;
      });
      // 获取绑定状态
      this.$api.host.getWithdrawAccount().then((res) => {
        if (res.data?.id != 0) {
          this.isBind = true;
        }
      });
    },
  },
  created() {
    window?.luck?.hideTitleBar();
    // 为了加快开发效率，不请求权限接口。
    this.loading = false;
    this.getAppInfo();
    this.showInfo = true;
    this.getInfo();

    // 判定有没有权限查看数据
    this.$api.host
      .getHostInfo()
      .then((res) => {
        console.log("getHostInfo res:", res.data);
        this.loading = false;
        if (res.data.hostProfileAccess) {
          this.showInfo = true;
          this.getInfo();
        } else {
          this.$router.push({ path: "/NoAccess" });
        }
      })
      .catch((err) => {
        this.loading = false;
        console.log("getHostInfo err:", err);
        this.$toast.fail("请求失败");
      });

    // 调用数据上报接口
    // window.addEventListener("beforeunload", () => this.postReportData());
    // setInterval(() => {
    //   // console.log("set interval", this.report_event);
    //   console.log("-- main this -- ", this);
    //   this.postReportData("间隔上报");
    // }, 1000 * 5);
  },
};
</script>

<style lang="less" scoped>
.Earnings-period {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: 118px;
  background: linear-gradient(139deg, #ffdd00 0%, #ffb900 100%);
  border-radius: 8px 8px 8px 8px;
  opacity: 1;
  position: relative;

  .line {
    width: 1px;
    height: 24px;
    opacity: 0.05;
    background-color: #121212;
  }

  .text {
    font-size: 12px;
    font-family: Roboto-Bold, Roboto, Noto Sans, SF UI Text-Medium, SF UI Text,
      Helvetica;
    font-weight: 500;
    color: #121212;
    line-height: 18px;
  }

  .value {
    width: 130px;
    font-size: 20px;
    font-family: Roboto-Bold, Roboto, Noto Sans, SF UI Text-Bold, SF UI Text,
      Helvetica;
    font-weight: bold;
    color: #121212;
    line-height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    .usd {
      margin-left: 8px;
      font-size: 12px;
      font-family: Roboto-Bold, Roboto, Noto Sans, SF UI Text-Bold, SF UI Text,
        Helvetica;
      font-weight: bold;
      color: #121212;
      line-height: 30px;
    }
  }

  .content {
    display: flex;
    justify-content: space-around;
    align-items: center;
    z-index: 10;
  }

  .today,
  .week {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .view {
    display: flex;
    justify-content: center;
    height: 18px;
    font-size: 12px;
    font-family: Roboto-Bold, Roboto, Noto Sans, SF UI Text-Bold, SF UI Text,
      Helvetica;
    font-weight: bold;
    color: #121212;
    line-height: 18px;
    z-index: 10;

    div {
      width: 18px;
      height: 18px;
      margin-left: 5px;

      img {
        width: 100%;
      }
    }
  }

  .bg-img {
    width: 136px;
    position: absolute;
    right: 0;
    bottom: 0;
  }
}

.sub-title {
  margin: 16px 0 8px 0;
  display: inline-block;
  height: 24px;
  font-size: 16px;
  font-family: Roboto-Bold, Roboto, Noto Sans, SF UI Text-Bold, SF UI Text,
    Helvetica;
  font-weight: bold;
  color: #121212;
  line-height: 24px;
}

.level-ratio {
  padding: 16px;
  background: #ffffff;
  font-size: 16px;
  font-family: Roboto-Bold, Roboto, Noto Sans, SF UI Text-Bold, SF UI Text,
    Helvetica;
  border-radius: 8px 8px 8px 8px;
  border: 1px solid #ebebeb;
  display: flex;
  flex-direction: column;

  .level,
  .ratio {
    display: flex;
    justify-content: space-between;
    height: 38px;
  }

  .title {
    font-size: 14px;
    font-family: Roboto-Bold, Roboto, Noto Sans, SF UI Text-Medium, SF UI Text,
      Helvetica;
    font-weight: 500;
    color: #121212;
    line-height: 38px;
  }

  .value {
    font-size: 14px;
    font-family: Roboto-Bold, Roboto, Noto Sans, SF UI Text-Bold, SF UI Text,
      Helvetica;
    font-weight: bold;
    color: #121212;
    line-height: 38px;
    display: flex;
    align-items: center;
  }

  .ratio {
    display: flex;
    align-items: center;

    img {
      width: 24px;
      height: 24px;
      margin: 0 2px;
    }
  }
}

.van-swipe {
  // 解决ios在动画过程中圆角变方角
  overflow: hidden;
  transform: rotate(0deg);
  border-radius: 8px 8px 8px 8px;
  height: 84px;

  .activity-link {
    height: 84px;
    display: block;

    img {
      height: 100%;
      width: 100%;
      border: 0 none;
    }
  }
}

/deep/.van-swipe__indicator {
  transition-property: width;
  transition-timing-function: linear;
  transition-duration: 0.5s;
  border-radius: 6px;
}

/deep/.van-swipe__indicator--active {
  background-color: #ffdd00;
  width: 16px;
}

/deep/.van-loading {
  margin: 40vh auto;
}
</style>
