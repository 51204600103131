<template>
  <div class="ml16 mr16">
    <!-- <router-link to="/IncomeReport">go to IncomeReport</router-link> -->
    <BackAndTitle :path="'/'" :title="'income report'" />
    <div class="income-period">
      <div class="top">
        <div class="today">
          <span class="text">{{ $t("Earnings today") }}</span>
          <span class="value"
            ><span>+</span>{{ dailyIncome
            }}<span class="usd">[≈ ${{ dailyIncomeUsd }}]</span></span
          >
        </div>
        <div class="line"></div>
        <div class="week">
          <span class="text">{{ $t("Earnings this week") }}</span>
          <span class="value"
            ><span>+</span>{{ weeklyIncome
            }}<span class="usd">[≈ ${{ weeklyIncomeUsd }}]</span></span
          >
        </div>
      </div>
      <div class="bottom">
        <div class="half">
          <span class="text">{{ $t("Half month income") }}</span>
          <span class="value"
            ><span>+</span>{{ halfMonthlyIncome
            }}<span class="usd">[≈ ${{ halfMonthlyIncomeUsd }}]</span></span
          >
        </div>
        <div class="line"></div>
        <div class="monthly">
          <span class="text">{{ $t("Earnings this month") }}</span>
          <span class="value"
            ><span>+</span>{{ monthlyIncome
            }}<span class="usd">[≈ ${{ monthlyIncomeUsd }}]</span></span
          >
        </div>
      </div>
      <img class="bg-img" src="@/assets/My-Earnings-bg@2x.png" alt="" />
    </div>
    <div class="server-time">
      <span class="text">{{
        $t("Data is calculated according to UTC+0 time")
      }}</span
      ><br />
      <span class="value">(UTC+0 {{ calculateTime }})</span>
    </div>
    <span class="detail-title">{{ $t("income details") }}</span>
    <div class="income-detail">
      <IncomeDetailTotal
        :text="'today'"
        :range="'today'"
        :value="dailyIncome"
      />
      <div class="line"></div>
      <IncomeDetailSubclass
        :range="'today'"
        :type="'gift'"
        :text="'Gift proceeds'"
        :value="dailySubtotal.GIFT"
      />
      <IncomeDetailSubclass
        :range="'today'"
        :type="'video'"
        :text="'Video telephony revenue'"
        :value="dailySubtotal.VIDEO"
      />
    </div>
    <div class="income-detail">
      <IncomeDetailTotal
        :text="'this week'"
        :range="'weekly'"
        :value="weeklyIncome"
      />
      <div class="line"></div>
      <IncomeDetailSubclass
        :range="'weekly'"
        :type="'gift'"
        :text="'Gift proceeds'"
        :value="weeklySubtotal.GIFT"
      />
      <IncomeDetailSubclass
        :range="'weekly'"
        :type="'video'"
        :text="'Video telephony revenue'"
        :value="weeklySubtotal.VIDEO"
      />
    </div>
    <QuickFeedback />
  </div>
</template>

<script>
import BackAndTitle from "@/components/BackAndTitle";
import IncomeDetailTotal from "@/components/IncomeDetailTotal";
import IncomeDetailSubclass from "@/components/IncomeDetailSubclass";
import QuickFeedback from "@/components/QuickFeedback";
export default {
  name: "IncomeReport",
  components: {
    IncomeDetailTotal,
    IncomeDetailSubclass,
    QuickFeedback,
    BackAndTitle,
  },
  data() {
    return {
      dailyIncome: "",
      weeklyIncome: "",
      halfMonthlyIncome: "",
      monthlyIncome: "",
      calculateTime: "",
      dailySubtotal: {},
      weeklySubtotal: {},
    };
  },
  created() {
    this.$api.host.getIncomeReport().then((res) => {
      this.dailyIncome = res.data?.dailyIncome;
      this.weeklyIncome = res.data?.weeklyIncome;
      this.halfMonthlyIncome = res.data?.semimonthlyIncome;
      this.monthlyIncome = res.data?.monthlyIncome;
      this.calculateTime = res.data?.calculateTime;
      this.dailySubtotal = res.data?.dailySubtotal;
      this.weeklySubtotal = res.data?.weeklySubtotal;
      this.dailyIncomeUsd = res.data?.dailyIncomeUsd;
      this.weeklyIncomeUsd = res.data?.weeklyIncomeUsd;
      this.halfMonthlyIncomeUsd = res.data?.semimonthlyIncomeUsd;
      this.monthlyIncomeUsd = res.data?.monthlyIncomeUsd;
      // console.log("----------res----------", res);
      // this.dailyIncome = 11111;
      // this.weeklyIncome = 22222;
      // this.halfMonthlyIncome = 33333;
      // this.monthlyIncome = 44444;
      // this.calculateTime = 55;
      // this.dailySubtotal = 66;
      // this.weeklySubtotal = 77;
      // this.dailyIncomeUsd = 222;
      // this.weeklyIncomeUsd = 333;
      // this.halfMonthlyIncomeUsd = 444;
      // this.monthlyIncomeUsd = 555;
    });
  },
};
</script>

<style lang="less" scoped>
.income-period {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  margin-top: 16px;
  height: 152px;
  background: linear-gradient(139deg, #ffdd00 0%, #ffb900 100%);
  border-radius: 8px 8px 8px 8px;
  opacity: 1;
  position: relative;
  .line {
    width: 1px;
    height: 24px;
    opacity: 0.05;
    background-color: #121212;
  }
  .text {
    font-size: 12px;
    font-family: Roboto-Bold, Roboto, Noto Sans, SF UI Text-Medium, SF UI Text,
      Helvetica;
    font-weight: 500;
    color: #121212;
    line-height: 18px;
  }
  .value {
    width: 130px;
    font-size: 20px;
    font-family: Roboto-Bold, Roboto, Noto Sans, SF UI Text-Bold, SF UI Text,
      Helvetica;
    font-weight: bold;
    color: #121212;
    line-height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    .usd {
      margin-left: 8px;
      font-size: 12px;
      font-family: Roboto-Bold, Roboto, Noto Sans, SF UI Text-Bold, SF UI Text,
        Helvetica;
      font-weight: bold;
      color: #121212;
      line-height: 30px;
    }
  }
  .top,
  .bottom {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    z-index: 10;
  }
  .today,
  .week,
  .half,
  .monthly {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  .bg-img {
    width: 136px;
    position: absolute;
    right: 0;
    bottom: 0;
  }
}
.server-time {
  margin-top: 4px;
  font-size: 12px;
  font-family: Roboto-Bold, Roboto, Noto Sans, SF UI Text-Medium, SF UI Text,
    Helvetica;
  font-weight: 500;
  color: #999999;
  line-height: 18px;
}
.detail-title {
  margin-top: 16px;
  display: inline-block;
  // width: 120px;
  height: 24px;
  font-size: 16px;
  font-family: Roboto-Bold, Roboto, Noto Sans, SF UI Text-Bold, SF UI Text,
    Helvetica;
  font-weight: bold;
  color: #121212;
  line-height: 24px;
}
.income-detail {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  margin-top: 8px;
  height: 178px;
  background: #ffffff;
  border-radius: 8px 8px 8px 8px;
  opacity: 1;
  border: 1px solid #ebebeb;
  .line {
    height: 1px;
    background-color: #ebebeb;
  }
}
</style>
