<template>
  <div class="wrap ml16 mr16" v-web-title :data-title="$t(title)">
    <div class="header">
      <img src="@/assets/logo/512.png" alt="">
    </div>
    <div class="content">
      <div class="flexc-b">
        <div class="status flexc-b">
          <div v-show="status=='process'" class="top flexc-b">
            <img class="process-img" src="@/assets/Icon-160x160-process@2x.png" alt="">
            <span>{{$t('processing')}}</span>
          </div>
          <div v-show="status=='success'" class="top flexc-b">
            <img src="@/assets/Icon-160x160-success@2x.png" alt="">
            <span>{{$t('Recharge successful')}}</span>
          </div>
          <div v-show="status=='fail'" class="top flexc-b">
            <img src="@/assets/Icon-160x160-fail@2x.png" alt="">
            <span>{{$t('Recharge failed')}}</span>
          </div>
          <div v-show="orderInfo.userName" class="down">
            <div class="item">
              <span class="key">{{$t('Recharge account')}}:</span>
              <span class="value">ID:{{ orderInfo.userName }}</span>
            </div>
            <div class="item">
              <span class="key">{{$t('Recharge amount')}}:</span>
              <span
                class="value">{{orderInfo.localCurrencySymbol}}{{orderInfo.rechargeAmount}}</span>
            </div>
            <div class="item">
              <span class="key">{{$t('Recharge gold coins')}}:</span>
              <span class="value">{{ orderInfo.coinCount }} Coins</span>
            </div>
            <div class="item">
              <span class="key">{{$t('Recharge order number')}}:</span>
              <span class="value">{{ orderInfo.orderId }}</span>
            </div>
            <div class="item">
              <span class="key">{{$t('Recharge time')}}:</span>
              <span class="value">{{ orderInfo.createTime }}</span>
            </div>
          </div>
        </div>
        <div v-show="status=='success'" class="tips">
          {{$t('Please log in to the app to check the specific gold coins transfer status. If you have any questions, please contact the official customer service')}}
        </div>
      </div>
      <div>
        <div @click="goHome" class="btn mt16" :class="{'loading':!onLoad}">
          {{$t('Continue buying gold coins')}}
        </div>
        <div v-if="isApp" class="btn mt16" :class="{'loading':!onLoad}">
          <a href="luckluck://main/home">{{$t('Exit the payment page')}}</a>
        </div>
      </div>
    </div>
    <van-overlay class="loading" :show="!onLoad">
      <div class="loading-box flexc-e">
        <van-loading size="48px" />
        <span>{{$t('Loading')}}...</span>
      </div>
    </van-overlay>
  </div>
</template>

<script>
export default {
  name: "NoAccess",
  components: {},
  data() {
    return {
      // 订单状态：process | success | fail
      status: "",
      orderInfo: {},
      timer: null,
      onLoad: false,
      title: "Order status",
      isApp: false,
    };
  },
  methods: {
    goHome() {
      if (this.onLoad) {
        this.loadPage("/pay");
      }
    },
    getOrderStatus() {
      let orderId = this.$route.query.OrderId;
      let thirdOrder = this.$route.query.TxnId;
      this.$api.pay.getOrderStatus(orderId, thirdOrder).then((res) => {
        if (res.code == 0) {
          this.orderInfo = res.data;
          if (this.orderInfo.buyStatus == "SUCCESS") {
            this.status = "success";
          } else if (this.orderInfo.buyStatus == "THIRD_PROCESSING") {
            this.status = "process";
          } else {
            this.status = "fail";
          }
          this.onLoad = true;
          if (this.status !== "process") {
            clearInterval(this.timer);
          }
        } else {
          this.$toast({
            message: res.msg,
          });
        }
      });
    },
  },
  created() {
    this.isApp = this.getAppInfo() ? true : false;
    if (localStorage.lang) {
      this.$i18n.locale = localStorage.lang;
    }
    this.getOrderStatus();
    this.timer = setInterval(() => {
      this.getOrderStatus();
    }, 2000);
  },
  destroyed() {
    clearInterval(this.timer);
  },
};
</script>

<style lang="less" scoped>
.wrap {
  padding: 8px 16px 60px;
  .header {
    box-sizing: border-box;
    padding-top: 8px;
    position: relative;
    height: 40px;
    text-align: center;
    img {
      height: 32px;
      width: 32px;
    }
  }
  .content {
    height: calc(100vh - 120px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    .status {
      margin-top: 24px;
      padding-bottom: 24px;
      box-sizing: border-box;
      width: 343px;
      height: 388px;
      background: url("@/assets/pay-status-bg.png") no-repeat top 6% center;
      background-size: contain;
      .top {
        padding: 45px;
        img {
          width: 68px;
          height: 68px;
        }
        span {
          margin-top: 16px;
          font-size: 20px;
          font-family: Roboto-Bold, Roboto, Noto Sans, SF UI Text-Bold,
            SF UI Text, Helvetica;
          font-weight: bold;
          color: #121212;
          line-height: 30px;
        }
      }
      .down {
        width: 100%;
        .item {
          display: flex;
          justify-content: space-between;
          margin-bottom: 8px;
          padding: 0 32px;
          .key {
            font-size: 12px;
            font-family: Roboto-Bold, Roboto, Noto Sans, SF UI Text-Medium,
              SF UI Text, Helvetica;
            font-weight: 500;
            color: #666666;
            line-height: 18px;
          }
          .value {
            font-size: 12px;
            font-family: Roboto-Bold, Roboto, Noto Sans, SF UI Text-Bold,
              SF UI Text, Helvetica;
            font-weight: bold;
            color: #121212;
            line-height: 18px;
          }
        }
      }
    }
    .tips {
      padding: 0 16px;
      font-size: 12px;
      font-family: Roboto-Bold, Roboto, Noto Sans, SF UI Text-Medium, SF UI Text,
        Helvetica;
      font-weight: 500;
      color: #666666;
      line-height: 18px;
      text-align: center;
    }
    .btn {
      background: #39cc80;
      width: 280px;
      height: 48px;
      border-radius: 8px;
      text-align: center;
      line-height: 48px;
      a {
        color: #121212;
        display: inline-block;
        width: 100%;
        height: 100%;
      }
    }
    .loading {
      background: #aaa;
      opacity: 0.5;
    }
  }
}
.process-img {
  animation: animal 4s infinite linear;
  -webkit-transform-origin: center center;
  -ms-transform-origin: center center;
  transform-origin: center center;
}
@keyframes animal {
  0% {
    transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
  }
}
.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  background: none;
  .loading-box {
    padding: 20px 0;
    box-sizing: border-box;
    width: 160px;
    height: 128px;
    background: #121212;
    border-radius: 16px 16px 16px 16px;
    opacity: 0.8;
    color: #fff;
    span {
      font-size: 16px;
      font-family: SF UI Text-Bold, SF UI Text;
      font-weight: bold;
      color: #ffffff;
      line-height: 24px;
    }
  }
}
</style>
