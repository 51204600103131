<template>
  <div class="ml16 mr16">
    <BackAndTitle :path="{ target: 'closeH5' }" :title="'Anchor Center'" />
    <div class="content">
      <img src="@/assets/Icon-160x160-NoAccess@2x.png" alt="" />
      <span>{{$t("")}}This function is not open yet, please wait~</span>
    </div>
  </div>
</template>

<script>
import BackAndTitle from "@/components/BackAndTitle";

export default {
  name: "NoAccess",
  components: { BackAndTitle },
};
</script>

<style lang="less" scoped>
.content {
  height: calc(100vh - 100px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  img {
    width: 160px;
    height: 160px;
  }
}
</style>
