<template>
  <div class="mr16 ml16">
    <BackAndTitle :path="'/AnchorCenter/IncomeReport'" :title="'income details'" />
    <div class="total mt24 mb24">
      <!-- 总计头部 -->
      <div class="top mb8">
        <span class="title">{{$t(incomeDetail.incomeRange)}}</span>
        <div class="value">
          <span>{{$t("total")}}:</span>
          <img src="@/assets/Icon-32x32-Gold@2x.png" alt="" />
          <span>{{ incomeDetail.sum }}</span>
        </div>
      </div>
      <!-- 总计说明 -->
      <div class="explain">{{ incomeDetail.calculateTime }} (UTC)</div>
    </div>
    <div v-for="(item, index) in incomeDetail.items" :key="index" class="mb16 detail">
      <!-- 头部标题 -->
      <div class="top mt8 ml16 mr16">
        <div class="title">
          <template v-if="item.incomeType == 'GIFT'"><img src="@/assets/Icon-20x20-Gift@2x.png"
              alt="" /><span>{{
              $t('Gift proceeds')
            }}</span></template>
          <template v-if="item.incomeType == 'VIDEO'"><img
              src="@/assets/Icon-24x24-Video-blue@2x.png" alt="" />
            <span>{{ $t('Video telephony revenue') }}</span>
          </template>
        </div>
        <div class="value">
          <img src="@/assets/Icon-32x32-Gold@2x.png" alt="" />
          <span>{{ item.incomeValue }}</span>
        </div>
      </div>
      <!--▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼ 说明 ▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼-->
      <!-- 礼物的图片、类型及数量 -->
      <div v-if="item.incomeType == 'GIFT'" class="gift-box mb8 ml16 mr16">
        <img :src="item.giftImage" alt="" />
        <span>{{ item.giftName }}*1</span>
      </div>
      <!-- 视频收益来源：好友和非好友 -->
      <div v-if="item.incomeType == 'VIDEO'" class="gift-source mb8 ml16 mr16">
        {{ $t(item.callType) }}
      </div>
      <!-- 收益的时间 -->
      <div class="time ml16 mr16">{{ item.timestamp }}(UTC)</div>
      <!-- 礼物收益来源：私信、匹配视频、好友视频等 -->
      <div v-if="item.incomeType == 'GIFT'" class="gift-source mb8 ml16 mr16">
        {{ $t(item.sourceType) }}
      </div>
      <!-- 视频收益的时长 -->
      <div v-if="item.incomeType == 'VIDEO'" class="duration ml16 mr16">
        {{ transformSecondEn(item.duration) }}
      </div>
      <!-- 视频收益的价格 -->
      <div v-if="item.incomeType == 'VIDEO'" class="price ml16 mr16">
        <span>{{ item.price }}</span>
        <img src="@/assets/Icon-32x32-Gold@2x.png" alt="" />
        <span>/Min</span>
      </div>
      <div class="line mb8"></div>
      <!-- 关联用户的信息 -->
      <div class="bottom ml16 mr16 mb8">
        <div>
          <a class="other" :href="'luckluck://user/profile?uid='+item.sourceUid">
            <img class="avater" :src="item.sourceAccountAvatar" alt="" />
            <span>{{ item.sourceAccountName }}(UID:{{ item.sourceAccount }})</span>
            <img class="view" src="@/assets/Icon-16x16-Right-black@2x.png" alt="" />
          </a>
        </div>
        <a class="video"
          :href="'luckluck://video/start?userRole='+incomeDetail.account.accountRole+'&videoCallType=NO_FRIEND_DEEPLINK&uid='+item.sourceUid">
          <img class="view" src="@/assets/Icon-16x16-Video-yellow@2x.png" alt="" />
        </a>
      </div>
      <!--▲▲▲▲▲▲▲▲▲▲▲▲▲▲▲▲▲▲▲ 说明 ▲▲▲▲▲▲▲▲▲▲▲▲▲▲▲▲▲▲▲-->
    </div>
  </div>
</template>

<script>
import BackAndTitle from "@/components/BackAndTitle";

export default {
  name: "PhonePrice",
  components: { BackAndTitle },
  data() {
    return {
      incomeDetail: {},
    };
  },
  created() {
    this.$api.host
      .getIncomeDetail({
        incomeRange: this.$route.query.incomeRange,
        incomeType: this.$route.query.incomeType,
      })
      .then((res) => {
        this.incomeDetail = res.data;
      });
  },
};
</script>

<style lang="less" scoped>
.total {
  background: #ebebeb;
  border-radius: 8px 8px 8px 8px;
  padding: 16px;
  .top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    font-family: Roboto-Bold, Roboto, Noto Sans, SF UI Text-Bold, SF UI Text,
      Helvetica;
    font-weight: bold;
    color: #121212;
    line-height: 22px;
    .title {
      font-size: 14px;
      font-family: Roboto-Bold, Roboto, Noto Sans, SF UI Text-Bold, SF UI Text,
        Helvetica;
      font-weight: bold;
      line-height: 22px;
    }
    .value {
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 20px;
        height: 20px;
        margin: 0 4px;
      }
    }
  }
  .explain {
    font-size: 12px;
    font-family: Roboto-Bold, Roboto, Noto Sans, SF UI Text-Medium, SF UI Text,
      Helvetica;
    font-weight: 500;
    color: #666666;
    line-height: 18px;
  }
}
.detail {
  background: #ffffff;
  border-radius: 8px 8px 8px 8px;
  border: 1px solid #ebebeb;
  .top {
    display: flex;
    justify-content: space-between;
    margin-bottom: 4px;
    .title,
    .value {
      display: flex;
      align-items: center;
      img {
        width: 20px;
        height: 20px;
        margin-right: 4px;
      }
      span {
        font-size: 14px;
        font-family: Roboto-Bold, Roboto, Noto Sans, SF UI Text-Bold, SF UI Text,
          Helvetica;
        font-weight: bold;
        color: #121212;
        line-height: 22px;
      }
    }
  }
  .gift-box {
    display: flex;
    align-items: center;
    font-size: 14px;
    font-family: Roboto-Bold, Roboto, Noto Sans, SF UI Text-Medium, SF UI Text,
      Helvetica;
    font-weight: 500;
    color: #121212;
    line-height: 22px;
    img {
      width: 16px;
      height: 16px;
      margin-right: 4px;
    }
  }
  .gift-source {
    font-size: 14px;
    font-family: Roboto-Bold, Roboto, Noto Sans, SF UI Text-Medium, SF UI Text,
      Helvetica;
    font-weight: 500;
    color: #666666;
    line-height: 22px;
  }
  .time,
  .duration,
  .price {
    font-size: 14px;
    font-family: Roboto-Bold, Roboto, Noto Sans, SF UI Text-Medium, SF UI Text,
      Helvetica;
    font-weight: 500;
    color: #666666;
    line-height: 22px;
    margin-bottom: 4px;
  }
  .price {
    display: flex;
    align-items: center;
    img {
      width: 16px;
      height: 16px;
      margin: 0 2px;
    }
  }
  .line {
    height: 1px;
    background-color: #ebebeb;
  }
  .bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .other {
      display: flex;
      align-items: center;
      font-size: 12px;
      font-size: 12px;
      height: 12px;
      font-family: Roboto-Bold, Roboto, Noto Sans, SF UI Text-Medium, SF UI Text,
        Helvetica;
      font-weight: 500;
      color: #121212;
      line-height: 12px;
      .avater {
        border-radius: 4px;
        width: 16px;
        height: 16px;
        margin-right: 4px;
      }
      .view {
        width: 12px;
        margin-left: 4px;
        height: 12px;
      }
    }
    .video {
      width: 20px;
      height: 20px;
      img {
        width: 100%;
      }
    }
  }
}
</style>
