var dsBridge = require("dsbridge");
export default {
  //方法名，web传递给原生的数据，原生返回的回调函数
  callFn(name, data, callback) {
    callback(dsBridge.call(name, data, callback));
  },
  //方法名，原生传递过来的数据
  registerFn(tag, callback) {
    dsBridge.register(tag, callback);
  }
};
