<template>
  <div class="mr16 ml16">
    <BackAndTitle :path="'/'" :title="'video phone price'" />
    <PhonePriceItem class="mt24" :price="strangerVideoPrice" :explain="'Super Video Call Price'"
      :non_view="true" />
    <PhonePriceItem class="mt16" :price="friendVideoPrice" :explain="'Friend Video Call Price'"
      :non_view="true" />
    <PhonePriceItem class="mt16" :price="'price interpretation'"
      :explain="'The average call time is short, please pay attention to increase the call time with other users and increase the answering rate of video calls;'"
      :non_view="true" :non_unit="true" />
  </div>
</template>

<script>
import BackAndTitle from "@/components/BackAndTitle";
import PhonePriceItem from "@/components/PhonePriceItem";

export default {
  name: "PhonePrice",
  components: { BackAndTitle, PhonePriceItem },
  data() {
    return {
      friendVideoPrice: "",
      strangerVideoPrice: "",
    };
  },
  created() {
    this.$api.host.getPhonePrice().then((res) => {
      console.log("getPhonePrice res:", res.data);
      this.friendVideoPrice = res.data.friendVideo;
      this.strangerVideoPrice = res.data.strangerVideo;
    });
  },
};
</script>

<style lang="less" scoped></style>
