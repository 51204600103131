import Vue from "vue";
import App from "./App.vue";

// 样式初始化
import "normalize.css";

// 全局样式
import "@/assets/css/common.less";
import "@/assets/css/aihelp.less";

// 路由
import VueRouter from "vue-router";
import router from "@/router/index";
Vue.use(VueRouter);

//导入API接口,将api挂载到vue的原型上,组件内通过this.$api. 方式调用。
import api from "@/request/api";
Vue.prototype.$api = api;

//动态标题
function changeTitle(el, binding) {
  const { value } = binding;
  if (el.dataset.title) {
    document.title = el.dataset.title;
  } else if (value && value.title) {
    document.title = value.title;
  }
}
Vue.directive("web-title", {
  inserted: function (el, binding) {
    changeTitle(el, binding);
  },
  update: function (el, binding) {
    changeTitle(el, binding);
  },
});

// 混入
import { mixin } from "@/mixin";
Vue.mixin(mixin);

// 使用dayjs
import dayjs from "dayjs";
Vue.prototype.dayjs = dayjs;

// 引入dsbridge,web和native通信桥梁
import dsBridge from "@/utils/dsbridge";
Vue.prototype.$bridge = dsBridge;

// 移动端调试工具
import Vconsole from "vconsole";
let vConsole = null;

if (
  process.env.NODE_ENV == "testing" ||
  process.env.NODE_ENV == "development"
) {
  // 测试和开发打开，生产不能打开
  vConsole = new Vconsole({ maxLogNumber: 1000 }); //打印1000次后自动清除
  Vue.use(vConsole);
}
export default vConsole;
import Cookies from "js-cookie";

// 多语言
import VueI18n from "vue-i18n";
import EN from "@/assets/lang/en";
import ZH from "@/assets/lang/zh";
import TR from "@/assets/lang/tr";
Vue.use(VueI18n);
const i18n = new VueI18n({
  locale: "en",
  messages: {
    en: EN,
    zh: ZH,
    tr: TR,
  },
  silentTranslationWarn: true,
});

// 全局注册vant基础组件
import {
  Button,
  Cell,
  CellGroup,
  Icon,
  Image as VanImage,
  Col,
  Row,
  Popup,
  Toast,
  Field,
  Form,
  Swipe,
  SwipeItem,
  Lazyload,
  Loading,
  Picker,
  RadioGroup,
  Radio,
  Search,
  ActionSheet,
  List,
  Overlay,
} from "vant";
Vue.use(Button)
  .use(Cell)
  .use(CellGroup)
  .use(Icon)
  .use(VanImage)
  .use(Col)
  .use(Row)
  .use(Popup)
  .use(Toast)
  .use(Field)
  .use(Form)
  .use(Swipe)
  .use(SwipeItem)
  .use(Lazyload)
  .use(Loading)
  .use(Picker)
  .use(RadioGroup)
  .use(Search)
  .use(Overlay)
  .use(ActionSheet)
  .use(List)
  .use(Radio);

Vue.config.productionTip = false;

new Vue({
  router,
  i18n,
  render: (h) => h(App),
  created() {
    try {
      let userInfo = null;
      // IOS获取用户信息
      let iosUserInfo = Cookies.get("userInfo");
      if (iosUserInfo) {
        userInfo = iosUserInfo;
      }
      // 安卓获取用户信息
      let androidUserInfo = window?.luck?.getInfo(); //返回的数据格式是字符串类型
      if (androidUserInfo) {
        userInfo = androidUserInfo;
      }
      userInfo = JSON.parse(userInfo);
      // 用indexof判断userInfo.language是否包含语言标识zh、en、tr设置语言
      if (userInfo.language.indexOf("zh") > -1) {
        this.$i18n.locale = "zh";
      } else if (userInfo.language.indexOf("en") > -1) {
        this.$i18n.locale = "en";
      } else if (userInfo.language.indexOf("tr") > -1) {
        this.$i18n.locale = "tr";
      }
    } catch (error) {
      console.log(error);
    }
  },
}).$mount("#app");
