import reportData from "@/request/api/report";
export const mixin = {
  data() {
    return {
      appInfo: null,
      browserInfo: null,
      isApp: false,
      report_device: null,
      report_event: [],
    };
  },
  methods: {
    // 组装上报参数
    createReportData(eventParams) {
      console.log("调用组装参数方法 🐱 参数： >>>", eventParams);
      // 设备数据（report_device）
      // 事件数据（report_event）
      // 当设备数据为null时，判断设备类型是APP还是浏览器
      if (this.report_device == null) {
        // 根据设备类型，获取更新相应的设备信息
        let appInfo = this.getAppInfo();
        if (appInfo) {
          // APP环境
          this.report_device = {
            time: Date.now(),
            version: appInfo?.version,
            system_language: appInfo?.language,
            device_id_2: appInfo?.device_id_2,
            app_id: appInfo?.app_id,
            platform_type: appInfo?.platform,
            channel: appInfo?.channel,
            gender: appInfo?.gender,
            country_ab: appInfo?.countryAb,
            device_id: appInfo?.device_id,
            system_version: appInfo?.system_version,
            capi: appInfo?.capi,
            brand: appInfo?.brand,
            model: appInfo?.model,
          };
        } else {
          // 浏览器环境
          let browserInfo = this.getBrowserInfo();
          this.report_device = {
            time: Date.now(),
            version: browserInfo.ver,
            platform_type: browserInfo.browser,
          };
        }
      }
      // 拼装参数和事件信息平装
      eventParams.time = Date.now();
      // 把事件数据push到report_event
      this.report_event.push(eventParams);
    },
    // 上报方法
    postReportData() {
      // 当report_event不为空，则调用上报接口
      console.log("判断是否有事件数据  参数： >>>", this.report_event);
      if (this.report_event.length > 0) {
        // 调用上报接口后，清空事件数据
        reportData.report({
          report_device: this.report_device,
          report_event: this.report_event,
        });
        console.log("调用上报接口 🐶 清空事件数据");
        this.report_event = [];
      }
    },
    // 获取app信息
    getAppInfo() {
      try {
        let appInfo = JSON.parse(window.luck.getInfo());
        console.log("ENV:APP -- appInfo >>>", appInfo);
        return appInfo;
      } catch (error) {
        console.log("ENV:Browser");
        return null;
      }
    },
    // 获取浏览器信息
    getBrowserInfo() {
      var Sys = {};
      var ua = navigator.userAgent.toLowerCase();
      var re = /(msie|firefox|chrome|opera|version).*?([\d.]+)/;
      var m = ua.match(re);
      Sys.browser = m[1].replace(/version/, "'safari");
      Sys.ver = m[2];
      return Sys;
    },
    _typeof(obj) {
      var s = Object.prototype.toString.call(obj);
      return s.match(/\[object (.*?)\]/)[1].toLowerCase();
    },
    // 路由跳转
    loadPage(path, query) {
      console.log("跳转到" + path);
      console.log("query:" + JSON.stringify(query));
      if (Object.prototype.toString.call(path) === "[object Object]") {
        switch (path.target) {
          case "closeH5":
            window?.luck?.close();
            if (
              window.webkit &&
              window.webkit.messageHandlers &&
              window.webkit.messageHandlers.closeH5
            ) {
              window.webkit.messageHandlers.closeH5.postMessage('')
            }
            break;

          default:
            break;
        }
      } else {
        this.$router.push({ path, query });
      }
    },
    // 打开aihelp
    openAIHelp() {
      let appInfo = this.getAppInfo();
      if (appInfo) {
        location.href = "luckluck://function/customer_service";
      } else {
        window.openAIHelp();
      }
    },
    // ▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼ 表单验证规则 ▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼
    // 1、指定的长度，长度是rule中的length。
    specifiedLength(value, rule) {
      console.log("value:", value);
      console.log("rule:", rule);
      return value.length == rule.length;
    },
    // 2、指定的范围，范围是rule中的min和max。
    specifiedExtents(value, rule) {
      console.log("value:", value);
      console.log("rule:", rule);
      return value.length > rule.min && value.length < rule.max;
    },
    // 3、指定的正则表达式，模式是rule中的pattern。
    specifiedPattern(value, rule) {
      console.log("value:", value);
      console.log("rule:", rule);
      let pattern = new RegExp(rule.pattern);
      return pattern.test(value);
    },
    // ▲▲▲▲▲▲▲▲▲▲▲▲▲▲▲ 表单验证规则 ▲▲▲▲▲▲▲▲▲▲▲▲▲▲▲
    // XX秒 => X天X时X分X秒
    transformSecond(second_time) {
      var time = parseInt(second_time) + "秒";
      if (parseInt(second_time) > 60) {
        var second = parseInt(second_time) % 60;
        var min = parseInt(second_time / 60);
        time = min + "分" + second + "秒";
        if (min > 60) {
          min = parseInt(second_time / 60) % 60;
          var hour = parseInt(parseInt(second_time / 60) / 60);
          time = hour + "小时" + min + "分" + second + "秒";
          if (hour > 24) {
            hour = parseInt(parseInt(second_time / 60) / 60) % 24;
            var day = parseInt(parseInt(parseInt(second_time / 60) / 60) / 24);
            time = day + "天" + hour + "小时" + min + "分" + second + "秒";
          }
        }
      }
      return time;
    },
    transformSecondEn(second_time) {
      var time = parseInt(second_time) + " s ";
      if (parseInt(second_time) > 60) {
        var second = parseInt(second_time) % 60;
        var min = parseInt(second_time / 60);
        time = min + " Min " + second + " s ";
        if (min > 60) {
          min = parseInt(second_time / 60) % 60;
          var hour = parseInt(parseInt(second_time / 60) / 60);
          time = hour + " h " + min + " Min " + second + " s ";
          if (hour > 24) {
            hour = parseInt(parseInt(second_time / 60) / 60) % 24;
            var day = parseInt(parseInt(parseInt(second_time / 60) / 60) / 24);
            time = day + " d " + hour + " h " + min + " Min " + second + " s ";
          }
        }
      }
      return time;
    },
  },
};
