<template>
  <div>
    <a href="luckluck://function/buy_coins">我的-金币充值</a><br /><br />
    <a href="luckluck://web/buy_coins">金币弹窗</a><br /><br />
    <a href="luckluck://match/start">匹配</a><br /><br />
    <a href="luckluck://home?logic=match">探索页面-匹配</a><br /><br />
    <a href="luckluck://home">探索页面-无匹配</a><br /><br />
    <a href="luckluck://match/start?logic=guide">进入到匹配页-性别筛选</a><br /><br />
    <a href="luckluck://message/friends">IM-好友列表</a><br /><br />
    <a href="luckluck://message/online_notice">IM-上下线开关列表</a><br /><br />
    <a href="luckluck://main/message">消息列表</a><br /><br />
    <a href="luckluck://app_team?conversation_id=h1000000002">官方助手</a><br /><br />
    <a href="luckluck://sys_notify?conversation_id=h1000000003">系统消息</a><br /><br />
    <a href="luckluck://profit_notify?conversation_id=h1000000004">收益通知</a><br /><br />
    <a href="luckluck://main/mine">我的</a><br /><br />
    <a href="luckluck://home/profile">进入个人中心再进入修改资料</a><br /><br />
    <van-field v-model="uid" clearable label="UID" placeholder="请输入UID" /><br />
    <a :href="'luckluck://user/profile?uid='+uid">指定用户(UID:{{uid}})个人主页</a><br /><br />
    <a
      :href="'luckluck://message/chat?friend=0&uid='+uid">跳转指定用户(UID:{{uid}})私聊-触发添加好友扣金币流程</a><br /><br />
    <a
      :href="'luckluck://video/start?userRole=COMMON_USER&videoCallType=OTHER&uid='+uid">视频呼叫指定用户(UID:{{uid}})</a><br /><br />
  </div>
</template>
<script>
export default {
  name: "DeepLink",
  data() {
    return {
      uid: "",
    };
  },
};
</script>