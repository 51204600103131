export default {
  general: {
    backAndTitle: "{title}"
  },
  "The network is abnormal, please check the network condition":
    "Bağlantı problemi var, lütfen ağ durumunu kontrol ediniz",
  "Service error, please try again later":
    "Servis hatası, lütfen daha sonra tekrar deneyiniz",
  "Network Error": "Ağ Hatası",
  "Facebook login": "Facebook ile Giriş",
  "Google login": "Google ile Giriş",
  "By registering, you agree to our ##User Agreement##and ##Privacy Policy##":
    "Kaydolduğunuzda ##User Agreement## ve ##Privacy Policy## kabul edilmiş sayılır.",
  "Sign in with Apple": "Apple ile giriş yapın.",
  "requesting...": "isteniyor...",
  "logging in...": "giriş yapılıyor...",
  "Login successful": "Giriş başarılı",
  "Login failed": "Giriş başarısız",
  "complete material": "Bilgileri tamamlayın",
  "Nick name": "Takma ad",
  Birthday: "Doğum günü",
  gender: "cinsiyet",
  "Contains prohibited words, please re-write":
    "Yasaklanmış kelimeler içeriyor, lütfen tekrar yazınız",
  male: "erkek",
  female: "kadın",
  "After gender selection, subsequent changes cannot be made":
    "Cinsiyet seçiminden sonra değişiklik yapılamaz",
  understood: "anlaşıldı",
  save: "kaydet",
  Sure: "Elbette",
  "The platform is only available to users over the age of 18, see ##'User Agreement'##":
    "Platform yalnızca 18 yaşın üzerindeki kullanıcılar tarafından kullanılabilir, bkz. ##'User Agreement'##",
  "Select date of birth": "Doğum tarihinizi seçiniz",
  "Please enter a nickname": "Lütfen kullanıcı adı giriniz",
  "Please select your gender": "Lütfen cinsiyet seçiniz",
  "Your account is logged in on another device. If it is not your operation, please change your account password as soon as possible!":
    "Başka bir cihazda hesabınıza giriş yapıldığını fark ettik. Bu işlem size ait değilse, lütfen hesabınızın şifresini en kısa sürede değiştirin!",
  "last login": "son giriş",
  "The login information has expired, please log in again~":
    "Giriş bilgilerinin süresi doldu, lütfen tekrar giriş yapın~",
  "The current account login failed, if you have any questions, please contact customer service":
    "Mevcut hesaba giriş başarısız oldu, ayrıntılar için müşteri hizmetleri ile iletişime geçin",
  feedback: "geri bildirim",
  closure: "kapat",
  "ID copied": "ID kopyalandı",
  "buy coins": "Lakçe satın al",
  "likes received": "beğeni sayısı",
  "Help and Feedback": "Yardım ve Geri Bildirim",
  "Anchor Center": "Yayıncı Merkezi",
  "Modify data": "Bilgileri değiştir",
  avatar: "profil fotoğrafı",
  preview: "ön izleme",
  "personal introduction": "kişisel bilgiler",
  "Introduce yourself~": "kendini tanıt~",
  "change username": "kullanıcı adını değiştir",
  "Please select date of birth": "Doğum tarihini gir",
  "Modify personal introduction": "Kişisel bilgilerini değiştir",
  "Enter nickname..": "Takma ad girin..",
  "Gender cannot be changed!": "Cinsiyet değiştirilemez!",
  "replace photo": "fotoğrafı değiştir",
  "delete photo": "fotoğrafı sil",
  Cancel: "İptal",
  "Your photo has been uploaded successfully!":
    "Fotoğrafın başarıyla yüklendi!",
  "Your photo upload failed, please click to try again!":
    "Fotoğraf yükleme başarısız oldu, tekrar denemek için tıkla!",
  "all photos": "tüm fotoğraflar",
  use: "kullan",
  "Album selection": "Albüm seçimi",
  Photograph: "Fotoğraf",
  "The avatar you edited at #time# has not passed the review, please upload again":
    "#time# zamanında düzenlediğin profil fotoğrafı onaylanmadı, lütfen tekrar yükle",
  "The photo you added at #time# has not been reviewed, please re-upload":
    "#time# zamanında eklediğin fotoğraf onaylanmadı, tekrar yüklemeyi dene",
  "Upload your real avatar and improve your personal information to get more exposure opportunities~":
    "Fotoğrafınızı ve diğer bilgilerini ekleyerek, daha fazla öne çıkabilirsin ~",
  "View now": "Şimdi görüntüle",
  "The new avatar you modified has taken effect, I wish you a happy use~":
    "Yeni profil fotoğrafı eklendi, iyi eğlenceler~",
  "The new photo you modified has taken effect, I wish you a happy use~":
    "Yeni fotoğraf eklendi, iyi eğlenceler~",
  "Login failed, the account and the device have been banned!":
    "Giriş başarısız, hesap ve cihaz yasaklandı!",
  "Login failed, the device has been banned!":
    "Giriş başarısız, cihaz yasaklandı!",
  "Login failed, the account has been banned!":
    "Giriş başarısız, hesap yasaklandı!",
  appeal: "Şikayet et",
  Notice: "Bildir",
  "blur effect": "bulanıklık efekti",
  blacklist: "Engellenenler Listesi",
  "about us": "Hakkımızda",
  "new version update": "güncel sürüm",
  "sign out": "oturumu kapat",
  "message notification": "mesaj bildirimi",
  "incoming call": "gelen arama",
  "Friends online reminder": "Çevrimiçi arkadaş bildirimi",
  "Privacy Policy": "Gizlilik Politikası",
  "User Agreement": "Kullanıcı Sözleşmesi",
  "delete account": "hesabı sil",
  "version number": "sürüm numarası",
  "Are you sure you want to log out?":
    "Oturumu sonlandırmak istediğinden emin misin?",

  remove: "kaldır",
  "Are you sure you want to remove this user from the blacklist?":
    "Bu kullanıcının engelini kaldırmak istediğine emin misin?",

  "Removed successfully": "Başarıyla kaldırıldı",
  "removal failed": "Kaldırma başarısız",
  "Are you sure you want to delete your account?":
    "Hesabınızı silmek istediğinden emin misin?",
  "please tell us why you left": "Neden ayrıldığını söylemek ister misin?",
  harassed: "Müstehcenlik",
  "worry about privacy": "gizlilik hakkında endişeliyim",
  "Frequent notifications": "Çok fazla bildirim geliyor",
  other: "Diğer",
  "other reasons": "Diğer sebepler",
  "Please tell us why you deleted your account":
    "Lütfen bize hesabını neden sildiğini söyle",
  Precautions: "Uyarı!",
  "If you delete your account, all user data such as your purchase history, gold coins, and friend list will be permanently deleted.":
    "Hesabını sildiğinde, satın alma geçmişin, lakçelerin ve arkadaş listen gibi tüm veriler kalıcı olarak silinecektir.",
  "Once deleted, this data cannot be recovered!":
    "Bir kez silindiğinde, bu veriler kurtarılamaz!",
  "I understand and agree that all account data above will be permanently deleted.":
    "Yukarıdaki hesap verilerinin tamamının kalıcı olarak silineceğini anlıyor ve kabul ediyorum.",
  "to chat with": "ile sohbet etme",
  information: "bilgi",
  "my good friend": "iyi arkadaşım",
  "search for friends": "arkadaşlarını ara",
  friend: "arkadaş",
  "Customer Service": "Müşteri Hizmetleri",
  "official news": "resmi bildiri",
  "official assistant": "yetkili kişi",
  "LuckLuck Team": "LuckLuck Takımı",
  "meet new friends": "yeni arkadaşlarla tanış",
  search: "arama",
  "No friends found": "Arkadaş bulunamadı",
  "Online reminder": "Çevrimiçi hatırlatıcı",
  collect: "topla",
  unfavorite: "beğeniyi kaldır",
  "set note name": "not adı ayarla",
  report: "bildir",
  "pull black": "arkadaşlıktan çıkar",

  "Online reminder is on": "Çevrimiçi hatırlatıcı açık",
  "Online reminder is off": "Çevrimiçi hatırlatıcı kapalı",
  "Enter a note name": "Bir not adı girin",
  "delete friend": "arkadaş sil",
  "Friends online notification": "Çevrimiçi arkadaş bildirimi",
  "opened number": "Cevaplanan arama sayısı",
  "Turn on a friend's online reminder and follow her at any time":
    "Bir arkadaşının çevrimiçi hatırlatıcısını aç ve ondan her an haberdar ol!",
  "Turn on a friend's online reminder and follow him at any time":
    "Bir arkadaşının çevrimiçi hatırlatıcısını aç ve ondan her an haberdar ol!",
  "Here you can set friend online reminder":
    "Buradan çevrimiçi arkadaş hatırlatıcısını ayarlayabilirsiniz",
  "Private letter": "Özel mektup",
  "Video chat": "Görüntülü sohbet",
  "Adding friends and starting a chat will cost xx gold":
    "Arkadaş ekleme ve sohbet başlatmak xxaltın tutar",
  "Insufficient gold coins, adding friends and starting a chat will consume xx gold coins":
    "Yetersiz altın jeton, arkadaş ekleme ve sohbet başlatmak xx altın tutacaktır",
  "Confirm add": "Eklemeyi onayla",
  "to recharge": "satın almak için",
  "After blacklisting, the friendship between the two parties will be canceled, and you will no longer receive any messages from the other party. You can cancel it in 'Settings - Blacklist'":
    "Engellenenler listesine alındıktan sonra, karşılıklı olarak arkadaşlık iptal edilecek ve artık karşı taraftan herhangi bir mesaj almayacaksınız. 'Ayarlar - Engellenenler Listesi' kısmından iptal edebilirsiniz.",
  "Blocked successfully": "Engelleme başarılı",
  "Failed to pull black": "Arkadaşlıktan çıkarma başarısız",
  "Failed to send, you have blocked the other party, you can continue chatting after unblocking":
    "Gönderilemedi, karşı tarafı engellediniz, engellemeyi kaldırdıktan sonra sohbete devam edebilirsiniz",
  "Failed to send, please add the other party as a friend first":
    "Gönderilemedi, lütfen önce diğer tarafı arkadaş olarak ekleyin",
  "Are you sure you want to delete this friend? Chat history will be cleared after deleting friends":
    "Bu arkadaşınızı silmek istediğinden emin misin? Arkadaşları sildikten sonra sohbet geçmişiniz temizlenecektir",
  "Delete friends successfully": "Arkadaşlar başarıyla silindi",
  "Failed to delete friend": "arkadaş silinemedi",
  "[picture]": "[fotoğraf]",
  "[Gift]": "[Hediye]",
  "[Telephone]": "[Telefon]",
  "[video]": "[video]",
  "#UserName# gived you a gift": "#UserName# size bir hediye gönderdi",
  "system notification": "sistem bildirimi",
  "Earnings notice": "Kazanç bildirimi",
  "new greeting message": "yeni karşılama mesajı",
  "Say Hi message": "Merhaba mesajı gönder",
  "delete this chat": "bu sohbeti sil",
  delete: "sil",
  "Are you sure you want to delete all chats with #USERNAME#?":
    "#USERNAME# ile tüm sohbetinizi silmek istediğinden emin misin?",
  "confirm deletion": "silmeyi onayla",
  "Hi, welcome to #AppName#, where you can meet a lot of fun and interesting new friends, I wish you a happy time here~":
    "Merhaba, birbirinden hoş yeni arkadaşlarla tanışabileceğin çok eğlenceli #AppName#'a hoş geldin, umarım keyifli vakit geçirirsin~",
  "xx greeting messages received": "xx karşılama mesajı alındı",
  "There are 17 new greeting messages": "17 yeni karşılama mesajı var",
  "I haven't received the greeting message yet, hurry up to match and meet more people":
    "Karşılama mesajını henüz almadıysanız, daha fazla insanla tanışmak için eşleşmeye başlayın",
  "go to match": "eşleşmeye başla",
  "Are you sure you want to set all hello messages as read, the unread message prompt will be cleared, and the list data will not be cleared":
    "Tüm mesajlarını okundu olarak işaretlemek istediğine emin misin? Mesajlar okundu olarak işaretlendiğinde okunmamış mesaj bildirimi almayacaksın ama mesajlara istersen tekrar ulaşabilirsin.",
  "all read": "hepsi okundu",
  "All messages unread hello message is set to read":
    "Tüm okunmamış mesajlar okundu olarak işaretlendi",
  "No unread messages yet": "Henüz okunmamış mesaj yok",
  "#USERNAME# would like to be friends with you":
    "#USERNAME# sizinle arkadaş olmak istiyor",
  agree: "Katılıyorum",
  ignore: "görmezden gel",
  "#USERNAME# has sent you a friend invitation":
    "#USERNAME# size bir arkadaşlık daveti gönderdi",
  "You are not friends yet, add friends to unlock more features":
    "Henüz arkadaş değilsiniz, daha fazla özelliği açmak için arkadaş ekleyin",
  "add friend": "arkadaş ekle",

  "You and #USERNAME# have become friends, let's chat together~":
    "Sen ve #USERNAME# arkadaş oldunuz, birlikte sohbet edin~",
  "You have initiated a friend request to #USERNAME#":
    "#USERNAME# için bir arkadaşlık isteği gönderdiniz",
  "We are already friends, let's chat together~":
    "Biz zaten arkadaşız, hadl sohbet edelim~",
  "Failed to send, the other party can only continue to send after replying to the message or becoming a friend":
    "Gönderilemedi, karşı taraf yalnızca mesajı yanıtladıktan veya arkadaş olduktan sonra göndermeye devam edebilir",
  "Failed to send the message, do you want to resend it?":
    "Mesaj gönderilemedi, tekrar göndermek istiyor musunuz?",
  Resend: "Yeniden gönder",
  copy: "kopyala",
  "Only if both parties are friends can make a video call":
    "Sadece iki taraf da arkadaş eklediği zaman görüntülü arama yapılabilir",
  "You can only send pictures when the other party replies to you or is a friend":
    "Yalnızca karşı taraf size cevap verdiğinde veya arkadaşınızsa fotoğraf gönderebilirsiniz",
  "Prohibited words detected, this message has been blocked":
    "Mesaj yasaklı kelimeler içeriyor, bu mesaj engellendi",
  "online status": "çevrimiçi durumu",
  "turn on": "aç",
  "Your online status is on, you will get a lot of video calls and exposure opportunities~":
    "Çevrimiçi özelliğini açtınız, artık öne çıkabilir ve daha çok arama alabilirsiniz~",
  "Your online status is off, which will reduce calls and suspend your exposure!":
    "Çevrimdışınız, aramalarınızı arttırmak ve öne çıkabilmek için çevrimiçi olun!",
  "Online Status Description": "Çevrimiçi Durum Açıklaması",
  "Whether the online switch is your work status switch, if you start to work, please turn on the work switch first, so that you will get a high exposure opportunity and a lot of video calls. If you can turn off the work switch when you are about to stop working, the platform will reduce your exposure opportunities~":
    "Çalışmaya başladığınızda statü düğmesini çevrimiçi hale getirin. Böylece öne çıkarılma fırsatı yakalarsınız. Yine çalışmayı bıraktığınızda da statü düğmenizi çevrimdışı yapın. Böylece öne çıkarılmanız sona erdirilir.",
  "my earnings": "kazançlarım",
  today: "bugün",
  "this week": "bu hafta",
  "View Income Report": "Gelir Raporunu Görüntüle",
  "Anchor strategy": "Yayıncı stratejisi",
  "video phone price": "görüntülü arama fiyatı",
  "Friend Video Call Price": "arkadaş görüntülü araması fiyatı",
  "Super Video Call Price": "Genel Görüntülü Arama Fiyatları",
  "Level and exchange rate": "Seviye ve döviz kuru",
  "Subscription ratio": "Abonelik Oranı",
  "last week grade": "geçen hafta notu",
  "Grade of the week": "Haftanın notu",
  "Withdrawal account": "Banka hesabı",
  "Bind the withdrawal account": "Banka hesabın bağla",
  bound: "Bağla",
  unbound: "Bağlantıyı Kes",
  "Encounter problems?": "Sorunla mı karşılaştın?",
  "Immediate Feedback": "Immediate Feedback",
  "price interpretation": "fiyat açıklaması",
  "Please avoid violations during the video":
    "Lütfen görüntülü arama sırasında ihlallerden kaçının",
  "The average call time is short, please pay attention to increase the call time with other users and increase the answering rate of video calls;":
    "Ortalama arama süreniz çok kısa, lütfen görüşme süresini artırmaya ve görüntülü aramaların cevaplama oranını artırmaya dikkat edin;",
  "This feature is not yet open, so stay tuned~":
    "Bu özellik henüz açık değil, takipte kalmaya devam edin~",
  "income report": "kazanç raporu",
  "Data is calculated according to UTC+0 time":
    "Veriler UTC+0 saatine göre hesaplanır",
  "Earnings today": "Bugünün kazancı",
  DAILY: "Bugünün kazancı",
  "Earnings this week": "Bu haftanın kazancı",
  WEEKLY: "Bu haftanın kazancı",
  "Half month income": "15 günlük kazanç",
  "Earnings this month": "Bu ayki kazanç",
  "income details": "kazanç ayrıntıları",
  "half a month": "15 gün",
  "this month": "bu ay",
  total: "Toplam",
  "Video telephony revenue": "Görüntülü konuşma kazancı",
  "Gift proceeds": "Hediye kazançları",
  "Active Task Earnings": "Aktif Görev Kazançları",
  "xx minutes xx seconds": "xx dakika xx saniye",
  "xx gold coins/minute": "xx altın para/dakika",
  "Due to the price adjustment of the platform, the price of your video has been automatically adjusted to xx gold coins/minute":
    "Platformun fiyat belirleme politikasına göre görüntülü konuşma fiyatı otomatik olarak xx altın para/dakika olarak ayarlanmıştır.",
  "Having trouble recharging? ##Contact Customer Service>##":
    "Satın almakla ilgili sorun mu yaşıyorsunuz? ##Müşteri Hizmetleri ile iletişime geçin>##",
  "Questions about your order? ##Contact Customer Service##":
    "Siparişinizle ilgili sorularınız mı var? ##Müşteri Hizmetleriyle İletişime Geçin##",
  "Failed to get coin pack, please try again later":
    "Paket alımı başarısız, lütfen daha sonra tekrar deneyiniz",
  Retry: "Yeniden dene",
  "Copy order number": "Sipariş numarasını kopyala",
  "buy goods": "Satın al",
  "Only show orders from the last 30 days":
    "Yalnızca son 30 güne ait siparişleri göster",
  "order number": "sipariş numarası",
  "order time": "sipariş zamanı",
  fail: "başarısız",
  success: "başarılı",
  processing: "İşleniyor",
  "Buying, please don't leave...":
    "Satın alma tamamlanıyor, lütfen ayrılmayın...",
  "successful recharge": "satın alma başarılı",
  "Recharge failed": "satın alma başarısız",
  "Order number copied": "Sipariş numarası kopyalandı",
  "Free Gold Rewards": "Ücretsiz Altın Ödülleri",
  "Congratulations on getting 50 free coins":
    "Tebrikler, ücretsiz 50 altın kazandınız!",
  "Get it for free": "Ücretsiz Al",
  "Congratulations, you have received xx gold coins for free":
    "Tebrikler, ücretsiz xx altın para kazandınız",
  "Buy now": "Şimdi satın al",
  "Tap the screen to start pairing": "Eşleşmeyi başlatmak için ekrana dokunun",
  all: "tüm",
  free: "bedava",
  "gender preference": "cinsiyet tercihi",
  recharge: "satın alma",
  "The balance of gold coins is insufficient, please recharge first~":
    "Altın para bakiyesi yetersiz, lütfen ekleme yapın",
  "In order to create a green and healthy video chat atmosphere and live up to the good times, please respect each other and chat in a civilized manner. The following violations are strictly prohibited during the video process":
    "Hoş ve eğlenceli bir sohbet ortamı oluşturup, güzel anılar biriktirmek için lütfen karşınızdakine saygı duyun. Konuşma esnasında aşağıdaki ihlallerin yapılması kesinlikle yasaktır",
  "No exposure of private parts, nudity, no clothing or very little clothing":
    "Özel bölgeleri göstermek, çıplaklık veya açık giyinmek yasaktır.",
  "Dirty language, violent behavior, dangerous behavior":
    "Küfür, şiddet içeren davranış, tehlikeli davranış",
  "New friends are coming at the speed of light, please don't quit early~":
    "Yeni arkadaşlar ışık hızında geliyor, lütfen ayrılmayın~",
  "Violations in the video will trigger a feature ban~":
    "Kural ihlalleri durumunda bazı özellikleriniz kısıtlanabilr.",
  "It's better to chat, you can add friends~":
    "Sohbet etmek iyidir, arkadaş ekleyebilirsin~",
  "Video connecting...": "Video bağlanıyor...",
  connecting: "Bağlanıyor",
  Next: "Sonraki",
  "The current gold coins are insufficient, continuing to match will consume xx gold coins/time":
    "Mevcut altın paralar yetersiz, eşleşmeye devam etmek xx altın para/zaman harcayacaktır",
  "gold coin recharge": "Altın para satın al",
  "Are you sure you want to quit matching":
    "Eşleşmeden çıkmak istediğinize emin misiniz?",
  quit: "çıkış ",
  "Any bad behavior is not allowed in the video chat. If you find any illegal behavior, you can choose to report or block the other party!":
    "Görüntülü sohbette herhangi bir ihlale izin verilmez. Herhangi bir kural dışı davranışa maruz kalırsanız, karşı tarafı şikayet edebilir ve engelleyebilirsiniz!",
  "Select the reason for reporting": "Raporlama nedenini seçin",
  "Thank you for your report, we will deal with it as soon as possible":
    "Raporunuz için teşekkür ederiz, en kısa sürede ilgileneceğiz",
  "Report failed, please try again later":
    "Rapor başarısız oldu, lütfen daha sonra tekrar deneyin",
  "sexual nudity": "müstehcenlik",
  "fake sex": "Yanlış cinsiyet",
  underage: "18 yaşından küçük",
  "Violent and bloody": "Şiddet içermekte",
  "Terrorism and politics": "Terör ve siyaset",
  "Swipe left to switch users": "Kullanıcıyı değiştirmek için sola kaydırın",
  "Please show your face for video chat":
    "Lütfen görüntülü sohbet için yüzünüzü gösterin",
  "The blur will disappear when #username# faces the camera":
    "#username# kameraya baktığında bulanıklık kaybolacak",
  "remove blur effect": "bulanıklık efektini kaldır",
  "you just sent a like": "az önce bir beğeni gönderdin",
  "The other person gave you a like": "Bir kişi seni beğendi",
  "friend request sent": "arkadaşlık isteği gönderildi",
  "The other party requested to add you as a friend":
    "Karşı taraf sizi arkadaş olarak eklemek istedi",
  "congratulations you have become friends": "tebrikler, arkadaş oldunuz",
  "You can hang up after the countdown is over":
    "Geri sayım bittikten sonra kapatabilirsiniz",
  beauty: "güzellik",
  whitening: "beyazlatma",
  "Abrade skin": "Yüz Saklama",
  "video match": "Görüntülü arama eşleşmesi",
  match: "eşleşme",
  "match history": "eşleşme geçmişi",
  duration: "süre",
  "No matching history": "eşleşme geçmişi yok",
  "start matching": "eşleşmeyi başlat",
  "You have spent xx gold coins to send a friend request successfully":
    "Başarılı bir arkadaşlık isteği göndermek için xx altın harcadınız",
  "Turn on gender filter": "Cinsiyet filtresini aç",
  "xx gold coins/time": "xx altın para/zaman",
  "Do you want to turn on gender filtering to continue matching girls?":
    "Kızlarla eşleşmeye devam etmek için cinsiyet filtrelemeyi açmak istiyor musunuz?",
  "Turning on gender screening and matching will cost xx gold coins/time. The current gold coin balance is insufficient, please recharge first!":
    "Cinsiyet seçme ve eşleşmeyi açmak, xx altın para/zaman tutacak. Mevcut altın para bakiyesi yetersiz, lütfen önce satın alım yapın!",
  "Which gender do you wish to match to?":
    "Hangi cinsiyetle eşleşmek istersiniz?",
  "Screening for gender matching will cost xx gold/time":
    "Cinsiyet seçimiyle eşleşme xx altın/zaman tutacak.",
  "There is no record of likes, go match to get more likes~":
    "Beğeni kaydı yok. Daha fazla beğeni almak için daha fazla eşleşme yapın~",
  "There are xx opportunities for free video calls per hour, and it will cost xxx gold coins/time to continue to make calls beyond the number of times":
    "Her saat xx ücretsiz görüntülü görüşme vardır ve birden fazla kez aramaya devam etmek xxx altın para/zamana mal olur.",
  "Video chat will cost xx [gold coins]/minute, less than 1 minute will be counted as 1 minute":
    "Görüntülü sohbet xx [altın para]/dakika tutacak, 1 dakikadan az görüşmeler 1 dakika olarak sayılacak",
  "Video chat will cost xx [gold coins]/minute, if it is less than 1 minute, it will be counted as 1 minute. Your balance is insufficient, please recharge~":
    "Görüntülü sohbet xx [altın para]/dakika tutacak. Sohbet 1 dakikadan kısa ise 1 dakika olarak sayılacaktır. Bakiyeniz yetersiz, lütfen satın alım yapın~",
  "The other party has set the call fee":
    "Karşı taraf arama ücretini belirledi",
  "call charges": "arama ücretleri",
  "call cancelled": "arama iptal edildi",
  Cancelled: "İptal edildi",
  "The other party has canceled": "Karşı taraf aramayı iptal etti",
  "Missed call": "Cevapsız çağrı",
  "The other party is temporarily unavailable":
    "Karşı taraf geçici olarak uygun değil",
  "The other party does not answer": "Karşı taraf cevap vermiyor",
  "call rejected": "arama reddedildi",
  rejected: "reddedildi",
  "call ended": "arama sona erdi",
  "The other party has hung up and the call is over":
    "Karşı taraf telefonu kapattı ve arama bitti",
  "video price": "görüntülü arama fiyatı",
  price: "fiyat",
  "connecting…": "Bağlanıyor…",
  "Waiting for the other party to answer...":
    "Karşı tarafın cevap vermesini bekleniyor...",
  "Insufficient gold coin balance, please recharge":
    "Yetersiz altın para, lütfen yeniden satın alım yapın.",
  "Remaining time: XXs": "Kalan bakiye: XXs",
  "Click to call back": "Tekrar aramak için tıklayın",
  "call duration": "Çağrı süresi",
  "Call revenue": "Çağrı kazancı",
  "invite you to a video call": "sizi bir video görüşmesine davet ediyor",
  "video call": "görüntülü arama",
  "missed call": "cevapsız çağrı",
  "Your video call with ##username## has ended, get xx gold coins~":
    "##username## ile görüntülü görüşmeniz sona erdi ve xx altın para kazandınız~",
  "LuckLuck Club": "LuckLuck Kulübü",
  recommend: "tavsiye etme",
  "up to date": "en yeni",
  Turkish: "Türkçe",
  Arabic: "Arapça",
  English: "İngilizce",
  online: "çevrimiçi",
  "busy line": "şuan meşgul",
  offline: "çevrımdışı",
  "Failed to get data, please refresh and try again":
    "Veri alınamadı, lütfen yenileyin ve tekrar deneyin",
  "Matching degree: xx%": "Eşleşme oranı: xx%",
  answer: "cevap",
  "Screenshots cannot be taken due to security policy restrictions":
    "Güvenlik politikası nedeniyle ekran görüntüsü alınamıyor",
  "The system detects that the other party is using a screen capture or screen recording tool, please pay attention to regulating personal behavior!":
    "Sistem, karşı tarafın bir ekran resmi veya videosu kaydetme aracı kullandığını algıladı, lütfen dikkat ediniz!",
  "Failed to send, the picture contains illegal sensitive information":
    "Gönderilemedi, fotoğraf ihlal içeriyor",
  "Violation warning": "İhlal uyarısı",
  "If you are not a friend, any pornography or nudity is not allowed. If you violate this rule again, your account functionality will be banned.":
    "Arkadaş değilseniz, herhangi bir pornografiye veya çıplaklığa izin verilmez. Bu kuralı tekrar ihlal ederseniz, hesabınız engellenecektir.",
  "Restricted use": "kısıtlı kullanım",
  "We're sorry, we've received reports from users of 'sexual or nudity' in your video chat. After the platform has verified that there is indeed a violation, the [xx function] will be temporarily frozen. Continued pornography or nudity will prolong the freeze time.":
    "Üzgünüz, görüntülü sohbet ettiğiniz kullanıcılardan 'cinsellik veya çıplaklık' raporu aldık. Uygulamamız gerçekten bir ihlal olduğunu doğruladıktan sonra [xx işlevi] geçici olarak dondurulacaktır. İhlalin devam etmesi durumunda dondurma süresi uzatılacaktır.",
  "Reporting time": "Raporlama zamanı",
  "limit time": "Zaman sınırı",
  unlock: "Özelliği aç",
  "Ban Unlock": "Özellik açmayı engelle",
  "Unlocking [xx function] will cost xx gold coins":
    "[xx işlevi] özelliğini açmak xx altın parakarşılığında olacak",
  "Unlocking [matching function] will cost xx gold coins, the balance is insufficient, please recharge first~":
    "[Eşleşme işlevi] özelliğini açmak için bakiye yetersiz, xx altın para tutarında olacak, lütfen önce satın alım yapın~",
  "Unlocked successfully, you can use the xx function":
    "Özellik başarıyla açıldı, xx işlevini kullanabilirsiniz",
  "Hello, because the platform detects that your gender does not match, it has been automatically changed to 'male/female'. If you have any questions, please contact customer service for feedback~":
    "Merhaba, uygulama cinsiyet seçiminizin hatalı olduğunu tespit ettiği için otomatik olarak 'erkek/kadın' olarak değiştirilmiştir. Herhangi bir sorunuz varsa, geri bildirim için lütfen müşteri hizmetleri ile iletişime geçin~",
  "There are only 10 free translations per day.":
    "Günde sadece 10 ücretsiz çeviri hakkı vardır.",
  "The translation function is enabled, you can chat freely~":
    "Çeviri özelliği açıldı, şimdi sohbet edebilirsiniz~",
  "Translation limit reached": "Çeviri sınırına ulaşıldı",
  "There are only xx free translations per day, recharge any amount to unlock the translation function":
    "Günde yalnızca xx ücretsiz çeviri hakkı vardır, çeviri işlevinin kilidini açmak için herhangi bir miktarda satın alım yapınız.",
  "new version discovered": "yeni sürüm keşfedildi",
  "The new version of xxxx has been released, hurry up and update the experience~":
    "xxxx'in yeni sürümü çıktı, lütfen uygulamayı güncelleyiniz~",
  "download update": "güncellemeyi indir",
  "Try it now": "Şimdi dene",
  "install new version": "yeni sürümü yükle",
  "Downloading Apk file...": "Apk dosyası indiriliyor...",
  "install now": "şimdi kur",
  "Apk file size: xxxM": "Apk dosya boyutu: xxxM",
  "The update package is downloading in the background...":
    "Güncelleme paketi arka planda indiriliyor...",
  "The new version of xxxxAPK package has been downloaded, please install and experience it":
    "xxxxAPK paketinin yeni sürümü geldi, lütfen cihazınızı güncelleyiniz",
  "Currently the latest version": "Şu anki son sürüm",
  Licensing: "Onaylama",
  "Video chat needs to open the following permissions":
    "Görüntülü sohbet için izinlerin açılması gerekiyor",
  camera: "kamera",
  "video call, take photo": "görüntülü arama, fotoğraf çekme",
  microphone: "mikrofon",
  "Output sound during video call": "Konuşma esnasında ses gelmiyor",
  "It is detected that some of your functions are not enabled, and the following permissions need to be opened for video chat":
    "Bazı özellikleriniz etkin değildir ve görüntülü sohbet edebilmek için ilgili izinlerin verilmesi gerekmektedir.",
  "In order to better answer the phone and get more exposure opportunities, please allow to stop optimizing the usage of the store site":
    "kontrol edilmeli",
  allow: "izin ver",
  "not allowed": "izin verme",
  "Manual customer service": "Müşteri hizmetleri kılavuzu",
  "contact us": "Bize Ulaşın",
  "If you have any questions, please contact customer service":
    "Herhangi bir sorunuz varsa, lütfen müşteri hizmetleri ile iletişime geçin.",
  "new news": "Yeni bildiriler",

  "Your friend [#UserName] is online, start chatting now~":
    "Arkadaşın [#UserName] çevrimiçi, şimdi sohbet etmeye başla~",
  "Click to start a private chat now >>":
    "Şimdi sohbet başlatmak için tıklayın >>",
  "invites you to a video call": "sizi bir video görüşmesine davet ediyor",
  "Turn on the message notification and never miss your friend's private message and call notification again~":
    "Mesaj ve çağrı bildirimlerini kaçırmamak için mesaj bildirimini açın",
  "Turn on message notification permission": "Mesaj bildirimlerini aç",
  "Get timely notifications from friends and incoming calls":
    "Arkadaş ve gelen arama bildirimlerini aç",
  "Enable message notification and online reminder":
    "Mesaj bildirimlerini ve çevrimiçi bildirimlerini aç",
  "To receive online alerts, you need to turn on the system push alerts and online alerts":
    "Arkadaşından çevrimiçi hatırlatma almak istiyorsan, bildirim ayanlarını ve çevrimiçi hatırlatıcıyı açmalısın",
  "Turn on message notifications": "Mesaj bildirimlerini aç",
  "If you want to receive the online reminder of Ta, you need to open the system push reminder":
    "Çevrimiçi uyarılarını almak için sistem ayarlarından özelliği açmanız gerekir.",
  "After closing, you will not be able to receive video call notifications from friends":
    "Kapattıktan sonra arkadaşlarınızdan görüntülü arama bildirimleri alamayacaksınız.",
  "Confirm to close": "Kapatmak için onaylayın",
  "": "",
  "Please enter bank account number": "Lütfen banka hesap numarasını girin",
  "Bank Account Number (IBAN)": "Banka Hesap Numarası (IBAN)",
  "account name": "hesap adı",
  "please enter your username": "lütfen kullanıcı adınızı girin",
  "The format of the bank account number is incorrect, please re-enter":
    "Banka hesap numarasının biçimi yanlış, lütfen tekrar girin",
  "Format error, please re-enter": "Biçim hatası, lütfen tekrar girin",
  "First Name": "İlk adı",
  "Last Name": "Soy isim",
  "SWIFT number": "SWIFT numarası",
  "Bank name": "banka adı",
  "bank account name": "banka hesap adı",
  "Please enter bank account name": "Lütfen banka hesabı adını girin",
  "Please enter bank name": "Lütfen banka adını girin",
  "Full Name": "Ad Soyad",
  "Papara account name": "Papara hesap adı",
  "*The account name can be the registered Papara mobile number, email or Papara number":
    "*Hesap adı kayıtlı Papara cep telefonu numarası, e-posta adresi veya Papara numarası olabilir.",
  "Don't have a Papara account yet? Go to Register Papara >":
    "Henüz bir Papara hesabınız yok mu? Papara'yı Kaydet'e gidin >",
  Papara: "papara",
  "cellphone number": "cep telefonu numarası",
  "e-mail": "e-posta",
  "Your user ID": "kullanıcı kimliğiniz",
  "Personal information": "Kişisel bilgi ",
  "Bank information": "banka bilgisi",
  "Please enter your first name": "Lütfen isminizi giriniz",
  "Please enter your last name": "Lütfen bir soyadı girin",
  "Please enter SWIFT number": "Lütfen SWIFT numarasını girin",
  "Confirm submission": "gönderimi onayla",
  "Back to modify": "değiştirmek için geri dön",
  "Modify information": "Bilgileri değiştir",
  "Can only contain letters, numbers, spaces, dashes (-), dots (.)":
    "Yalnızca harf, sayı, boşluk, kısa çizgi (-), nokta (.)",
  "The value must start with TR plus 24 digit":
    "içerebilir Değer TR artı 24 hane ile başlamalıdır",
  "Please enter Papara number": "Lütfen Papara numarasını giriniz",
  "Please enter Papara account name": "Lütfen Papara hesap adını girin",
  "Bank account number must be entered": "Banka hesap numarası girilmelidir",
  "Bank account name must be entered": "Banka hesap adı girilmelidir",
  "Bank name is required": "Banka adı gerekli",
  "SWIFT number must be entered": "SWIFT numarası girilmelidir",
  "First name is required": "İlk isim gerekli",
  "Last name is required": "Soyadı gerekli",
  "Papara number must be entered": "Papara numarası girilmelidir",
  "Papara account name must be entered": "Papara hesap adı girilmelidir",
  "Please check carefully to ensure that the information is correct, otherwise the transfer will not be possible.":
    "Lütfen bilgilerin doğru olduğundan emin olmak için dikkatlice kontrol edin, aksi takdirde transfer mümkün olmayacaktır",
  MATCH_VIDEO: "Eşleşen videolar",
  PRIVATE_MESSAGE: "Özel mektup",
  FRIEND_VIDEO_CALL: "Arkadaş videoları",
  STRANGER_VIDEO_CALL: "Super Video Arama",
  "Account type": "Hesap türü",
  "Click to select the account type": "Hesap türünü seç'e tıklayın",
  "Account ID": "Hesap Kimliği",
  "The phone": "Telefon",
  email: "E-posta",
  ID: "Hesap Kimliği",
  PHONE: "Telefon",
  EMAIL: "E-posta",
  "Account type must be entered": "Hesap türü girilmelidir",
  "No Papara": "Papara hesabınız yok mu",
  "Go to Register": "Şimdi kaydolmak için gidin",
  Street: "Cadde/Sokak",
  "Please enter street": "Lütfen cadde/sokak adını giriniz",
  "Street must be entered": "Cadde/sokak adı girilmeli",
  City: "Şehir",
  "Please enter city": "Lütfen şehir adını giriniz",
  "City must be entered": "Şehir adı girilmeli",
  Province: "Bölge (Şehir)",
  "Please enter province": "Lütfen bölgeyi (şehir) giriniz",
  "Province must be entered": "Bölge (şehir) adı girilmeli",
  Postcode: "Posta Kodu",
  "Please enter postcode": "Lütfen posta kodunu giriniz",
  "Postcode must be entered": "Posta Kodu girilmeli",
  // 购买金币
  // "Chinese (Simplified)": "Çince (Basitleştirilmiş)",
  // English: "İngilizce",
  // Turkish: "Türkçe",
  "Log in": "Giriş Yap",
  "Login successfully": "Giriş Başarılı",
  "Login failed, please enter a valid LuckLuck ID~":
    "Giriş başarısız, lütfen geçerli bir LuckLuck numarası giriniz~",
  "Enter LuckLuck ID to log in and recharge,":
    "Oturum açmak ve Lakçe yüklemek çin LuckLuck numaranızı girin,",
  "Previous order": "Geçmiş siparişler",
  "Select country/region": "Ülke/Bölge seçin",
  Global: "Global",
  Turkey: "Türkiye",
  "Select the number of gold coins": "Lakçe miktarını seçin",
  "Select the payment method": "Ödeme yöntemini seçin",
  "This amount is not currently supported": "Bu miktar şu anda desteklenmiyor",
  "Online  service": "Çevrimiçi Yardım",
  "LuckLuck official top-up platform to ensure your information security":
    "LuckLuck'ın yasal Lakçe yükleme platformu, bilgi güvenliğinizi korur",
  "Enter LuckLuck ID": "LuckLuck numarası girin",
  "Add other account": "Başka hesap ekleyin",
  "Enter UID": "LuckLuck numaranızı girin",
  "Enter LuckLuck ID to log in and recharge":
    "Giriş yapmak ve Lakçe yüklemek için LuckLuck numaranızı girin",
  "How to get LuckLuck ID？": "LuckLuck numarası nasıl alınır？",
  "Login area (not logged in)": "Oturum açma sayfası (oturum açılmamış)",
  "Login area (logged in-multi-account switching)":
    "Oturum açma sayfası (başka hesapla değiştir)",
  "Need help?": "Yardıma mı ihtiyacınız var?",
  "Go to help center": "Yardım Merkezine gidin",
  "help center": "yardım merkezi",
  "Recharge 60 gold coins": "60 Lakçe yükleyin",
  Recharging: "Yükleme",
  "Recharge successful": "Yükleme başarılı",
  // "Recharge failed": "Yükleme başarısız",
  "Recharge record": "Yükleme geçmişi",
  Copy: "Kopyala",
  "Payment amount": "Ödeme miktarı",
  "Payment platform": "Ödeme platformu",
  "Platform order number": "Platform sipariş numarası",
  time: "Zaman",
  "Recharge account": "Yükleme hesabı",
  "Recharge amount": "Yükleme miktarı",
  "Recharge gold coins": "Lakçe yükle",
  "Recharge order number": "Yükleme sipariş numarası",
  "Recharge time": "Yükleme zamanı",
  "Please log in to the app to check the specific gold coins transfer status. If you have any questions, please contact the official customer service":
    "Lakçe durumunuzu kontrol etmek için lütfen  oturum açın. Herhangi bir sorunuz varsa, lütfen müşteri hizmetleri ile iletişime geçin",
  "Back to homepage": "Ana sayfaya geri dön",
  // Processing: "İşlem sürdürülüyor",
  "[Reminder to account of gold coins] The xxx gold coins you purchased have been successfully transferred to your account":
    "[Altın hesabına hatırlatma] Satın aldığınız xxx Lakçe hesabınıza aktarıldı",
  "Payment failed, this channel is temporarily unavailable, please change another payment method or try again later~":
    "Ödeme başarısız oldu, bu kanal geçici olarak kullanılamıyor, lütfen ödeme yöntemini değiştirin veya daha sonra tekrar deneyin~",
  Ok: "Tamam",
  "Historical Orders": "Tarihi Düzenler",
  Loading: "yükleme",
  "No more": "Daha fazla yok.",
  "Copy success": "Başarıyı kopyala",
  "LuckLuck ID cannot be empty": "LuckLuck kimliği boş olamaz",
  "Order status": "Düzenleme durumu",
  "Continue buying gold coins": "Lakçe almaya devam et",
  "Exit the payment page": "Ödeme sayfasından çık"
};
