<template>
  <div @click="loadPage('/AnchorCenter/WithdrawalAccountInfo')" class="wrap">
    <div class="price-box">
      <div class="price">
        <span v-if="isBind" class="bound">{{$t('bound')}}
        </span>
        <span v-else>{{$t('unbound')}}</span>
      </div>
      <span class="type">{{$t('Bind the withdrawal account')}}</span>
    </div>
    <img class="view" src="@/assets/Icon-64x64-Right-Button@2x.png" alt="" />
  </div>
</template>

<script>
export default {
  name: "PhonePrice",
  props: ["isBind"],
};
</script>

<style lang="less" scoped>
.wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  padding: 16px;
  height: 86px;
  background: #ffffff;
  border-radius: 8px 8px 8px 8px;
  opacity: 1;
  border: 1px solid #ebebeb;
  .price-box {
    height: 52px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .price {
      display: flex;
      align-items: center;
      // width: 36px;
      height: 24px;
      font-size: 16px;
      font-family: Roboto-Bold, Roboto, Noto Sans, SF UI Text-Bold, SF UI Text,
        Helvetica;
      font-weight: bold;
      color: #eb5457;
      line-height: 24px;
      img {
        width: 24px;
        height: 24px;
        margin: 0 4px;
      }
    }
    .bound {
      color: #39cc80;
    }
    .type {
      width: 195px;
      height: 22px;
      font-size: 14px;
      font-family: Roboto-Bold, Roboto, Noto Sans, SF UI Text-Medium, SF UI Text,
        Helvetica;
      font-weight: 500;
      color: #666666;
      line-height: 22px;
    }
  }
  .view {
    width: 32px;
    height: 32px;
  }
}
</style>
