<template>
  <div @click="
      loadPage('/AnchorCenter/IncomeReport/IncomeDetails', { incomeRange })
    " class="total">
    <span class="text">{{$t(text)}}</span>
    <div class="value-box">
      <span class="text">{{$t('total')}}:</span>
      <div class="gold">
        <img src="@/assets/Icon-32x32-Gold@2x.png" alt="金币" />
      </div>
      <span class="value">{{ value }}</span>
      <div class="right">
        <img src="@/assets/Icon-16x16-Right-gray@2x.png" alt="查看更多" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "IncomeDetailTotal",
  props: ["text", "range", "value"],
  computed: {
    incomeRange() {
      // 收益周期: 1-今日收益 2-本周收益
      if (this.range == "today") {
        return "DAILY";
      } else if (this.range == "weekly") {
        return "WEEKLY";
      } else {
        return 0;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.total {
  padding: 0 16px;
  height: 22px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .text {
    height: 22px;
    font-size: 14px;
    font-family: Roboto-Bold, Roboto, Noto Sans, SF UI Text-Bold, SF UI Text,
      Helvetica;
    font-weight: bold;
    color: #121212;
    line-height: 22px;
  }
  .value-box {
    display: flex;
    align-items: center;
    .gold {
      height: 20px;
      width: 20px;
      margin: 0 4px;
      img {
        height: 100%;
      }
    }
    .right {
      margin-left: 4px;
      height: 16px;
      width: 16px;
      img {
        height: 100%;
      }
    }
  }
}
</style>
