<template>
  <div v-web-title :data-title="$t(title)">
    <div class="wrap">
      <div class="top">
        <img src="@/assets/logo/512.png" alt="">
        <span @click="displayLang=true">{{ language }}
          <van-icon :class="{'rotate180':displayLang}" class="transition3" name="arrow-down" />
        </span>
      </div>
      <van-action-sheet v-model="displayLang" :actions="langActions" @select="selectLang" />
      <!-- ▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼ 登录模块 ▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼ -->
      <div class="login-box mt24" v-if="!isApp">
        <!-- 登录模块/未登录 -->
        <div v-if="!isLogin" class="log-out">
          <div class="login">
            <input type="number" :placeholder="$t('Enter LuckLuck ID')" v-model="username">
            <div @click="pageLogin" class="btn">{{$t('Log in')}}</div>
          </div>
          <div class="tips">Tips：{{$t('Enter LuckLuck ID to log in and recharge,')}}<span
              @click="displayUidDialog=true"
              class="get-uid">{{$t('How to get LuckLuck ID？')}}</span>
          </div>
        </div>
        <!-- 登录模块/已登录 -->
        <div v-else @click="showUserList = !showUserList" class="logged-on flex-b">
          <div class="flex-s">
            <img :src="userList[currentUser].fullAvatarId" class="mr8" alt="">
            <div class="flexc-b user-info">
              <span class="nick-name mb4">{{ userList[currentUser].name }}</span>
              <span class="uid">ID:{{ userList[currentUser].username }}</span>
            </div>
          </div>
          <div class="more">
            <van-icon :class="{'rotate180':showUserList,}" class="transition3" name="arrow-down" />
          </div>
          <!-- 登录模块/已登录/用户列表 -->
          <div :class="{'opacity1':showUserList,}" class="user-list transition3">
            <div class="item-box">
              <div v-for="(item,index) in userList" :key="index">
                <div @click.stop="selectUser(index)" class="user-item flex-b">
                  <div class="flex-s">
                    <img :src="item.fullAvatarId" class="mr8" alt="">
                    <div class="flexc-b user-info">
                      <span class="nick-name mb4">{{ item.name }}</span>
                      <span class="uid">ID:{{ item.username }}</span>
                    </div>
                  </div>
                  <div @click.stop="deleteUser(index)" class="delete">
                    <van-icon name="cross" />
                  </div>
                </div>
              </div>
            </div>
            <div @click.stop="addUser" class="add-user flex-c">
              <van-icon name="plus" /><span class="ml8">{{$t('Add other account')}}</span>
            </div>
          </div>
        </div>
      </div>
      <!-- 登录模块/登录弹框 -->
      <van-overlay :show="displayLoginDialog" @click="displayLoginDialog = false">
        <div class="dialog-box">
          <div class="dialog-box-login" @click.stop>
            <div class="title mb16">{{$t('Enter LuckLuck ID to log in and recharge')}}</div>
            <input ref="userNameInput" type="number" :placeholder="$t('Enter LuckLuck ID')"
              v-model="username">
            <div @click="displayUidDialog=true" class="get-uid mt8 mb24">
              {{$t('How to get LuckLuck ID？')}}</div>
            <div @click="dialogLogin" class="btn">{{$t('Log in')}}</div>
          </div>
        </div>
      </van-overlay>
      <!-- 登录模块/获取ID引导 -->
      <van-overlay :show="displayUidDialog" @click="displayUidDialog = false">
        <div class="dialog-box">
          <div class="dialog-box-uid" @click.stop>
            <img class="guide" src="@/assets/guide-uid.png" alt="">
            <img class="close-guide" @click="displayUidDialog = false"
              src="@/assets/close-guide-uid.png" alt="">
          </div>
        </div>
      </van-overlay>
      <!-- ▲▲▲▲▲▲▲▲▲▲▲▲▲▲▲▲▲▲▲▲ 登录模块 ▲▲▲▲▲▲▲▲▲▲▲▲▲▲▲▲▲▲▲▲ -->
      <!-- 选择国家 -->
      <div class="country mt24" v-if="!isApp">
        <img class="mr8" src="@/assets/country/tr.svg" alt="" />
        <!-- <van-icon name="arrow-down" /> -->
      </div>
      <!-- 查询历史充值订单 -->
      <div @click="displayOrder = true" class="order mt24" v-else>
        <img class="mr8" src="@/assets/Icon-32x32-History@2x.png" alt="" />
        <span>{{$t('Historical Orders')}}</span>
      </div>
      <van-popup class="order-box" v-model="displayOrder" position="bottom" round
        :style="{ height: '70%' }">
        <div class="title">{{$t('Recharge record')}}</div>
        <van-list v-model="loading" :finished="finished" :finished-text="$t('No more')"
          @load="getHistoryOrder">
          <div v-for="(item,index) in historyOrderList" :key="index" class="order-item">
            <div class="flex-b mb8">
              <div class="recharge flex-s">{{$t('recharge')}} {{ item.coinCount }}
                <img src="@/assets/Icon-32x32-Gold2@2x.png" alt="" />
              </div>
              <div class="blue">
                <span v-if="item.buyStatus=='SUCCESS'">{{$t('success')}}</span>
                <span
                  v-else-if="item.buyStatus=='THIRD_PROCESSING'||item.buyStatus=='PRE_ORDER_SUCCESS'">{{$t('processing')}}</span>
                <span v-else>{{$t('fail')}}</span>
              </div>
            </div>
            <div class="flex-b mb8">
              <div class="key">{{$t('Payment amount')}}:</div>
              <div class="value">{{item.localCurrencySymbol}}{{ item.rechargeAmount }}</div>
            </div>
            <div class="flex-b mb8">
              <div class="key">{{$t('Payment platform')}}:</div>
              <div class="value">{{item.partners}}</div>
            </div>
            <div class="flex-b mb8">
              <div class="key">{{$t('Platform order number')}}:</div>
              <div class="value">{{item.orderId}}<span id="copy" :data-clipboard-text="item.orderId"
                  v-on:click="copy()" class="blue ml4">{{$t('copy')}}</span></div>
            </div>
            <div class="flex-b mb8">
              <div class="key">{{$t('time')}}:</div>
              <div class="value">{{item.rechargeTime}}</div>
            </div>
          </div>
        </van-list>
      </van-popup>
      <!-- ▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼ 金币包+渠道 ▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼ -->
      <div class="gold-title">
        {{$t('Select the number of gold coins')}}
      </div>
      <div class="gold-box">
        <!-- 点击设置当前金币包索引currentPackageIndex -->
        <div v-for="(item,index) in packageList" :key="index" @click="selectPackage(index)"
          class="item"
          :class="{'current-package':currentPackageIndex==index,'new-user':item.payCoinPackageType=='NEW_USER'}">
          <!-- tag等于1为hot标签 -->
          <div v-if="item.tag == 1" class="tag">hot</div>
          <div class="gold">
            <!-- discount等于0为没有折扣 -->
            <span v-if="item.discount!==0">{{item.discount}}% OFF</span>
            <img src="@/assets/Icon-128x80-gold-package@2x.png" alt="">
          </div>
          <div class="price">{{ item.coinCount }}</div>
        </div>
        <div class="item empty"></div>
      </div>
      <div class="channel-title">
        {{$t('Select the payment method')}}
      </div>
      <div class="channel-box">
        <div @click="handelBuyCoin(index)" v-for="(item,index) in currentPackage.relationList"
          :key="index" class="item flex-b" :class="{'gray':item.enable!==1}">
          <img :src="channelList[index].channelImage" alt="">
          <div v-if="item.enable==1" class="price">
            <span class="mr4">{{item.localCurrencySymbol}}{{ item.localPrice }}</span>
            <van-icon name="arrow" />
          </div>
          <div class="nonsupport" v-else>{{$t('This amount is not currently supported')}}</div>
        </div>
      </div>
      <!-- ▲▲▲▲▲▲▲▲▲▲▲▲▲▲▲▲▲▲▲▲ 金币包+渠道 ▲▲▲▲▲▲▲▲▲▲▲▲▲▲▲▲▲▲▲▲ -->
      <HelpCenter />
    </div>
    <van-overlay class="loading" :show="displayGoPay">
      <div @click="displayGoPay = false" class="loading-box flexc-e">
        <van-loading size="48px" />
        <span>{{$t('Loading')}}...</span>
      </div>
    </van-overlay>
    <OnlineService />
    <InfoSecurity />
  </div>
</template>

<script>
import OnlineService from "@/components/OnlineService";
import HelpCenter from "@/components/HelpCenter";
import InfoSecurity from "@/components/InfoSecurity";
import Clipboard from "clipboard";
export default {
  name: "PayCoin",
  components: { OnlineService, HelpCenter, InfoSecurity },
  data() {
    return {
      title: "buy coins",
      channelList: [],
      packageList: [],
      currentPackageIndex: 0,
      userList: [],
      currentUser: "",
      showUserList: false,
      isApp: false,
      displayLoginDialog: false,
      displayUidDialog: false,
      displayLang: false,
      displayOrder: false,
      displayGoPay: false,
      username: "",
      countryAb: "TR",
      language: "English",
      userInfo: "",
      langActions: [
        { name: "Türkçe" },
        { name: "English" },
        { name: "简体中文" },
      ],
      historyOrderList: [],
      loading: false,
      finished: false,
      current: 1,
      size: 10,
    };
  },
  computed: {
    isLogin() {
      if (this.userList.length == 0) {
        return false;
      } else {
        return true;
      }
    },
    currentPackage() {
      if (this.packageList.length == 0) {
        // return [{}];
        return "";
      } else {
        return this.packageList[this.currentPackageIndex];
      }
    },
  },
  mounted() {
    // 获取已存储的用户列表
    if (localStorage.userList) {
      this.userList = JSON.parse(localStorage.userList);
    }
    // 获取已存储的当前用户索引
    if (localStorage.currentUser) {
      this.currentUser = localStorage.currentUser;
    }
    if (localStorage.lang) {
      this.$i18n.locale = localStorage.lang;
      if (localStorage.lang == "en") {
        this.language = "English";
      } else if (localStorage.lang == "zh") {
        this.language = "简体中文";
      } else if (localStorage.lang == "tr") {
        this.language = "Türkçe";
      }
    }
  },
  watch: {
    // 本地持持久化：用户列表
    userList(newName) {
      if (!this.isApp) {
        localStorage.userList = JSON.stringify(newName);
      }
    },
    // 本地持持久化：当前用户索引
    currentUser(val) {
      if (!this.isApp) {
        localStorage.currentUser = val;
      }
      this.getPackage();
    },
    username(val, oldVal) {
      if (val.length > 20) {
        // this.$toast("最多输入20位");
        this.username = oldVal;
      }
    },
  },
  methods: {
    // 选择金币包
    selectPackage(index) {
      if (this.userList[this.currentUser]?.username) {
        this.currentPackageIndex = index;
      } else {
        this.displayLoginDialog = true;
        this.$nextTick(() => {
          this.$refs.userNameInput.focus();
        });
      }
    },
    // 复制订单
    copy() {
      let _this = this;
      let clipboard = new Clipboard("#copy");
      //复制成功
      clipboard.on("success", function () {
        _this.$toast(_this.$t("Copy success"));
        clipboard.destory();
      });
      //复制失败
      clipboard.on("error", function () {
        _this.$toast("Copy fail");
        clipboard.destory();
      });
    },
    // 获取历史订单数据
    getHistoryOrder() {
      this.displayOrder = true;
      if (!this.finished) {
        this.$api.pay
          .getHistoryOrder({
            userName: this.userList[this.currentUser].username,
            current: this.current,
            size: this.size,
          })
          .then((res) => {
            if (res.code == 0) {
              this.historyOrderList = this.historyOrderList.concat(
                res.data.records
              );
              this.loading = false;
              this.current++;
              if (res.data.total <= this.historyOrderList.length) {
                this.finished = true;
              }
            } else {
              this.$toast({
                message: this.$t("Service error, please try again later"),
              });
            }
          });
      }
    },
    // 获取历史订单

    // 选择语言
    selectLang(item) {
      this.displayLang = false;
      this.language = item.name;
      if (this.language == "English") {
        this.$i18n.locale = "en";
      } else if (this.language == "简体中文") {
        this.$i18n.locale = "zh";
      } else if (this.language == "Türkçe") {
        this.$i18n.locale = "tr";
      }
      if (!this.isApp) {
        localStorage.lang = this.$i18n.locale;
      }
    },
    addUser() {
      // 在用户列表，点击“other account”添加其他用户
      this.showUserList = false;
      this.displayLoginDialog = true;
      this.$nextTick(() => {
        this.$refs.userNameInput.focus();
      });
    },
    // 在用户列表，选择一个已登录用户
    selectUser(i) {
      this.currentUser = i;
      this.showUserList = false;
    },
    // 在用户列表，删除一个已登录用户
    deleteUser(i) {
      if (i <= this.currentUser) {
        if (this.currentUser > 0) {
          this.currentUser--;
        }
      }
      this.userList.splice(i, 1);
    },
    // 在页面直接登录
    pageLogin() {
      console.log("pageLogin");
      // uid为空弹框，不为空login
      if (this.username == "") {
        this.displayLoginDialog = true;
        this.$nextTick(() => {
          this.$refs.userNameInput.focus();
        });
      } else {
        this.login();
      }
    },
    // 在弹框中登录
    dialogLogin() {
      console.log("dialogLogin");
      // uid为提示不能为空，不为空login，
      if (this.username == "") {
        this.$toast(this.$t("LuckLuck ID cannot be empty"));
      } else {
        this.login();
      }
    },
    // 根据输入框的username获取用户信息，模拟登录。
    login() {
      // 提示成功或失败，登录成功uid设置为空,设置cookie
      this.$api.pay.getUser(this.username).then((res) => {
        if (res.code == 0) {
          this.$toast.success(this.$t("Login successfully"));
          this.displayLoginDialog = false;
          this.userList.push({
            fullAvatarId: res.data.fullAvatarId,
            name: res.data.name,
            username: res.data.username,
          });
          this.currentUser = this.userList.length - 1;
          this.username = "";
        } else {
          this.$toast({
            message: `<span style='word-break: break-word;'>${this.$t(
              "Login failed, please enter a valid LuckLuck ID~"
            )}</span>`,
            type: "html",
          });
        }
      });
    },
    // 获取当前用户所在地区的金币包，以及对应的支付渠道。
    getPackage() {
      let userName = this.userList[this.currentUser]?.username
        ? this.userList[this.currentUser]?.username
        : 0;
      this.$api.pay.getPackage(this.countryAb, userName).then((res) => {
        if (res.code == 0) {
          this.channelList = res.data.payChannelList;
          this.packageList = res.data.packageInfoList;
        } else {
          this.$toast({
            message: this.$t("Service error, please try again later"),
          });
        }
      });
    },
    handelBuyCoin(index) {
      if (this.userList[this.currentUser]?.username) {
        let params = {};
        if (this.isApp) {
          params = {
            appName: "LuckLuck",
            channelId: this.channelList[index].payChannelId,
            countryAb: "TR",
            goodsId: this.currentPackage.relationList[index].goodsId,
            partners: "CODA_PAY",
            payCoinPackageType: this.currentPackage.payCoinPackageType,
            platformEnum: "H5_APP",
            userName: this.userList[this.currentUser].username,
          };
        } else {
          params = {
            appName: "LuckLuck",
            channelId: this.channelList[index].payChannelId,
            countryAb: "TR",
            goodsId: this.currentPackage.relationList[index].goodsId,
            partners: "CODA_PAY",
            payCoinPackageType: this.currentPackage.payCoinPackageType,
            platformEnum: "H5_WEB",
            userName: this.userList[this.currentUser].username,
          };
        }
        if (this.currentPackage.relationList[index].enable == 1) {
          this.displayGoPay = true;
          this.$api.pay.buyCoin(params).then((res) => {
            if (res.code == 0) {
              window.location.href = res.data.jumpPath;
            } else if (res.code == 240011) {
              this.displayGoPay = false;
              this.$toast({
                message: this.$t(
                  "Payment failed, this channel is temporarily unavailable, please change another payment method or try again later~"
                ),
              });
            } else {
              this.displayGoPay = false;
              this.$toast({
                message: this.$t("Service error, please try again later"),
              });
            }
          });
        } else {
          this.$toast({
            message: this.$t("This amount is not currently supported"),
          });
        }
      } else {
        this.displayLoginDialog = true;
        this.$nextTick(() => {
          this.$refs.userNameInput.focus();
        });
      }
    },
  },
  created() {
    let userInfo = window?.luck?.getInfo(); //返回的数据格式是字符串类型
    if (typeof userInfo == "string") {
      this.isApp = true;
      this.userInfo = JSON.parse(userInfo);
      this.$i18n.locale = this.userInfo?.language;
      localStorage.lang = this.userInfo?.language;
      if (this.userInfo?.language == "en") {
        this.language = "English";
      } else if (this.userInfo?.language == "zh") {
        this.language = "简体中文";
      } else if (this.userInfo?.language == "tr") {
        this.language = "Türkçe";
      }
      this.userList.push({
        username: this.userInfo.userName,
      });
      this.currentUser = 0;
      // this.countryAb = this.userInfo.countryAb;
    } else {
      this.$i18n.locale = "tr";
    }
    this.getPackage();
  },
};
</script>

<style lang="less" scoped>
.wrap {
  padding: 8px 16px 60px;
  .top {
    box-sizing: border-box;
    padding-top: 8px;
    position: relative;
    height: 40px;
    text-align: center;
    img {
      height: 32px;
      width: 32px;
    }
    span {
      position: absolute;
      right: 0;
      top: 14px;
      font-size: 12px;
      font-family: Roboto-Bold, Roboto, Noto Sans, SF UI Text-Bold, SF UI Text,
        Helvetica;
      font-weight: bold;
      color: #121212;
    }
  }
  .login-box {
    background: #ebebeb;
    border-radius: 8px;
    padding: 16px;
    .log-out {
      .login {
        display: flex;
        justify-content: space-between;
        input {
          height: 48px;
          width: 214px;
          border-style: none;
          line-height: 1;
          padding: 13px 16px;
          margin: 0;
          box-sizing: border-box;
          font-size: 14px;
          font-family: Roboto-Bold, Roboto, Noto Sans, SF UI Text-Medium,
            SF UI Text, Helvetica;
          font-weight: 500;
          color: #999999;
          line-height: 22px;
          border-radius: 8px;
        }
        .btn {
          height: 48px;
          width: 89px;
          line-height: 48px;
          text-align: center;
          border-radius: 8px;
          background: #39cc80;
          user-select: none;
        }
      }
      .tips {
        padding: 8px 0 0;
        font-size: 12px;
        font-family: Roboto-Bold, Roboto, Noto Sans, SF UI Text-Medium,
          SF UI Text, Helvetica;
        font-weight: 500;
        color: #666666;
        line-height: 18px;
        .get-uid {
          color: #39cc80;
        }
      }
    }
    .logged-on {
      position: relative;
      .more,
      .delete {
        height: 48px;
        width: 48px;
        line-height: 48px;
        text-align: right;
      }
      .user-info {
        align-items: start;
        .nick-name {
          font-size: 16px;
          font-family: Roboto-Bold, Roboto, Noto Sans, SF UI Text-Bold,
            SF UI Text, Helvetica;
          font-weight: bold;
          color: #121212;
          line-height: 24px;
        }
        .uid {
          font-size: 12px;
          font-family: Roboto-Bold, Roboto, Noto Sans, SF UI Text-Medium,
            SF UI Text, Helvetica;
          font-weight: 500;
          color: #666666;
          line-height: 18px;
        }
      }
      img {
        width: 48px;
        height: 48px;
        border-radius: 100%;
      }
      .user-list {
        opacity: 0;
        visibility: hidden;
        z-index: 10;
        width: 100%;
        padding: 0 16px;
        border-radius: 8px;
        position: absolute;
        background: #fff;
        top: 68px;
        left: -16px;
        box-shadow: 0px 4px 12px 0px rgba(18, 18, 18, 0.12);
        .item-box {
          max-height: 400px;
          overflow: auto;
          &::-webkit-scrollbar {
            display: none; /* Chrome Safari */
          }
        }
        .user-item {
          padding: 16px 0;
          border-bottom: 1px solid #ebebeb;
        }
        .add-user {
          padding: 8px;
          font-size: 14px;
          font-family: Roboto-Bold, Roboto, Noto Sans, SF UI Text-Bold,
            SF UI Text, Helvetica;
          font-weight: bold;
          color: #2997ff;
          line-height: 22px;
        }
      }
      .opacity1 {
        visibility: visible;
        opacity: 1;
      }
    }
  }
  .country {
    font-size: 12px;
    img {
      width: 24px;
      // height: 10px;
    }
  }
  .order {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    img {
      width: 16px;
      height: 16px;
    }
  }
  .gold-title,
  .channel-title {
    font-size: 16px;
    font-family: Roboto-Bold, Roboto, Noto Sans, SF UI Text-Bold, SF UI Text,
      Helvetica;
    // font-family: Roboto-Bold, Roboto;
    // font-family: '宋体';
    font-weight: bold;
    color: #121212;
    line-height: 24px;
    margin: 16px 0;
  }
  .channel-title {
    margin-top: 6px;
  }
  .gold-box {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .item {
      display: flex;
      flex-direction: column;
      padding: 12px 16px;
      justify-content: space-between;
      position: relative;
      width: 109px;
      height: 110px;
      background: #ffffff;
      border-radius: 8px 8px 8px 8px;
      opacity: 1;
      border: 1px solid #ebebeb;
      margin-bottom: 10px;
      box-sizing: border-box;
      .tag {
        position: absolute;
        height: 20px;
        background: linear-gradient(135deg, #ff474a 0%, #eb4245 100%);
        border-radius: 8px 2px 8px 0px;
        font-size: 12px;
        font-family: Roboto-Bold, Roboto, Noto Sans, SF UI Text-Medium,
          SF UI Text, Helvetica;
        font-weight: 500;
        color: #ffffff;
        line-height: 20px;
        padding: 0 6px;
        top: -10px;
        left: -1px;
      }
      .gold {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 60px;
        text-align: center;
        font-size: 14px;
        font-family: Roboto-Bold, Roboto, Noto Sans, SF UI Text-Medium,
          SF UI Text, Helvetica;
        font-weight: 500;
        color: #eb5457;
        line-height: 14px;
        img {
          margin-top: 4px;
          width: 64px;
          height: 40px;
        }
      }
      .price {
        font-size: 20px;
        font-family: Roboto-Bold, Roboto, Noto Sans, SF UI Text-Bold, SF UI Text,
          Helvetica;
        font-weight: bold;
        color: #121212;
        line-height: 20px;
        text-align: center;
      }
    }
    .empty {
      height: 0px;
      visibility: hidden;
      margin-bottom: 0;
      padding: 0;
      border: 0;
    }
    .current-package {
      background: rgba(255, 185, 0, 0.08);
      border-radius: 8px 8px 8px 8px;
      opacity: 1;
      border: 1px solid #39cc80;
    }
    .new-user {
      background: #ff6669;
      .price,
      .gold {
        color: #fff;
      }
    }
    // .current-package.new-user {
    //   .price,
    //   .gold {
    //     color: #faf5eb;
    //   }
    // }
  }
  .channel-box {
    .item {
      box-sizing: border-box;
      margin-bottom: 16px;
      padding: 16px;
      height: 48px;
      background: #ffffff;
      border-radius: 8px 8px 8px 8px;
      opacity: 1;
      border: 1px solid #ebebeb;
      img {
        height: 16px;
      }
      .price {
        font-size: 14px;
        font-family: Roboto-Bold, Roboto, Noto Sans, SF UI Text-Medium,
          SF UI Text, Helvetica;
        font-weight: 500;
        color: #121212;
        line-height: 22px;
      }
      .nonsupport {
        font-size: 12px;
        font-family: Roboto-Bold, Roboto, Noto Sans, SF UI Text-Medium,
          SF UI Text, Helvetica;
        font-weight: 500;
        color: #999999;
        line-height: 18px;
      }
    }
    .gray {
      -webkit-filter: grayscale(100%);
      -moz-filter: grayscale(100%);
      -ms-filter: grayscale(100%);
      -o-filter: grayscale(100%);
      filter: grayscale(100%);
      filter: gray;
      background: #ebebeb;
    }
  }
}
.dialog-box {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .dialog-box-login {
    border-radius: 16px 16px 16px 16px;
    width: 300px;
    // height: 255px;
    background: #fff;
    padding: 16px 24px;
    box-sizing: border-box;
    .title {
      font-size: 16px;
      font-family: Roboto-Bold, Roboto, Noto Sans, SF UI Text-Bold, SF UI Text,
        Helvetica;
      font-weight: bold;
      color: #121212;
      line-height: 24px;
      text-align: center;
    }
    input {
      height: 48px;
      width: 100%;
      border: 1px solid #ebebeb;
      line-height: 1;
      padding: 13px 16px;
      margin: 0;
      box-sizing: border-box;
      font-size: 14px;
      font-family: Roboto-Bold, Roboto, Noto Sans, SF UI Text-Medium, SF UI Text,
        Helvetica;
      font-weight: 500;
      color: #999999;
      line-height: 22px;
      border-radius: 8px;
    }
    .get-uid {
      font-size: 14px;
      font-family: Roboto-Bold, Roboto, Noto Sans, SF UI Text-Medium, SF UI Text,
        Helvetica;
      font-weight: 500;
      color: #39cc80;
      line-height: 22px;
      text-align: center;
    }
    .btn {
      height: 48px;
      width: 100%;
      line-height: 48px;
      text-align: center;
      border-radius: 8px;
      background: #39cc80;
      user-select: none;
    }
  }
  .dialog-box-uid {
    display: flex;
    flex-direction: column;
    align-items: center;
    .guide {
      width: 260px;
    }
    .close-guide {
      width: 32px;
    }
  }
}
.rotate180 {
  transform: rotate(180deg);
}
.transition3 {
  transition: all 0.3s;
}
.order-box {
  .title {
    width: 100%;
    background-color: #fff;
    position: sticky;
    top: 0;
    font-size: 16px;
    font-family: Roboto-Bold, Roboto, Noto Sans, SF UI Text-Bold, SF UI Text,
      Helvetica;
    font-weight: bold;
    color: #121212;
    line-height: 48px;
    height: 48px;
    margin-bottom: 12px;
    text-align: center;
  }
  .order-item {
    padding: 8px 16px 0;
    width: 343px;
    margin: auto;
    border-radius: 8px 8px 8px 8px;
    border: 1px solid #ebebeb;
    box-sizing: border-box;
    margin-bottom: 12px;
    .recharge {
      font-size: 14px;
      font-family: Roboto-Bold, Roboto, Noto Sans, SF UI Text-Bold, SF UI Text,
        Helvetica;
      font-weight: bold;
      color: #121212;
      line-height: 22px;
      img {
        margin-left: 4px;
        width: 24px;
        height: 24px;
      }
    }
    .blue {
      font-size: 14px;
      font-family: Roboto-Bold, Roboto, Noto Sans, SF UI Text-Bold, SF UI Text,
        Helvetica;
      font-weight: bold;
      color: #2997ff;
      line-height: 22px;
    }
    .key {
      font-size: 12px;
      font-family: Roboto-Bold, Roboto, Noto Sans, SF UI Text-Medium, SF UI Text,
        Helvetica;
      font-weight: 500;
      color: #666666;
      line-height: 18px;
    }
    .value {
      font-size: 12px;
      font-family: Roboto-Bold, Roboto, Noto Sans, SF UI Text-Medium, SF UI Text,
        Helvetica;
      font-weight: 500;
      color: #121212;
      line-height: 18px;
      white-space: nowrap;
    }
  }
}
.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  background: none;
  .loading-box {
    padding: 20px 0;
    box-sizing: border-box;
    width: 160px;
    height: 128px;
    background: #121212;
    border-radius: 16px 16px 16px 16px;
    opacity: 0.8;
    color: #fff;
    span {
      font-size: 16px;
      font-family: SF UI Text-Bold, SF UI Text;
      font-weight: bold;
      color: #ffffff;
      line-height: 24px;
    }
  }
}
</style>
