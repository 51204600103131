/**
 * 接口域名的管理
 */
const base = {};
if (process.env.NODE_ENV === "development") {
  base.baseUrl = "https://api.luckluckchat.com";
  // console.log("development -- base.baseUrl >> ", base.baseUrl);
} else if (process.env.NODE_ENV === "testing") {
  base.baseUrl = "https://api.luckluckchat.com";
  // console.log("test -- base.baseUrl >> ", base.baseUrl);
} else {
  base.baseUrl = "https://api.luckluckchat.com";
  // console.log("produnct -- base.baseUrl >> ", base.baseUrl);
}
export default base;
